import { api } from './api';
import { MissionForm } from '../../globals';

const endpoints = (builder: any) => ({
  addMission: builder.mutation({
    query: ({ body }: { body: MissionForm }) => ({
      url: `mission`,
      method: 'POST',
      body: body,
    }),
    invalidatesTags: ['Mission'],
  }),
  getAllMissions: builder.query({
    query: () => `/mission`,
    providesTags: ['Mission'],
  }),
  getMissionById: builder.query({
    query: (id: string) => {
      return `/mission/${id}`;
    },
    providesTags: ['Mission'],
  }),
  toggleSprintElementDone: builder.mutation({
    query: ({
      sprintId,
      sprintElementId,
    }: {
      sprintId: number;
      sprintElementId: number;
    }) => ({
      url: `sprint/${sprintId}/element/${sprintElementId}/toggleDone`,
      method: 'PUT',
    }),
    invalidatesTags: ['Mission'],
  }),
  toggleClientApprovalSprint: builder.mutation({
    query: ({ sprintId }: { sprintId: number }) => ({
      url: `sprint/${sprintId}/clientApproval`,
      method: 'PUT',
    }),
    invalidatesTags: ['Mission'],
  }),
  toggleFreelanceApprovalSprint: builder.mutation({
    query: ({ sprintId }: { sprintId: number }) => ({
      url: `sprint/${sprintId}/freelanceApproval`,
      method: 'PUT',
    }),
    invalidatesTags: ['Mission'],
  }),
  addDocument: builder.mutation({
    query: ({
      body,
    }: {
      body: {
        projectId: number;
        formData: FormData;
        fileName: string;
        clientSignatureRequired: boolean;
      };
    }) => ({
      url: `mission/add-document`,
      method: 'POST',
      body,
    }),
    invalidatesTags: ['Mission'],
  }),
  addDispute: builder.mutation({
    query: ({
      body,
    }: {
      body: {
        projectId: number;
        name: string;
        description: string;
        disputeDate: string;
        priority: string;
      };
    }) => ({
      url: `mission/dispute`,
      method: 'POST',
      body,
    }),
    invalidatesTags: ['Mission'],
  }),
  signDocument: builder.mutation({
    query: ({
      body,
    }: {
      body: { projectId: number; documentId: number; formData: FormData };
    }) => ({
      url: `mission/sign-document`,
      method: 'POST',
      body,
    }),
    invalidatesTags: ['Mission'],
  }),
  getDocuments: builder.query({
    query: (id: string) => {
      return `/mission/${id}/documents`;
    },
  }),
  getDocument: builder.query({
    query: ({ id, documentId }: { id: number; documentId: number }) => {
      return `/mission/${id}/document/${documentId}`;
    },
  }),
  createDispute: builder.mutation({
    query: ({ missionId, body }: { missionId: number; body: any }) => ({
      url: `mission/${missionId}/dispute`,
      method: 'POST',
      body,
    }),
    invalidatesTags: ['Mission'],
  }),

  updateDispute: builder.mutation({
    query: ({
      missionId,
      disputeId,
      body,
    }: {
      missionId: number;
      disputeId: number;
      body: any;
    }) => ({
      url: `mission/${missionId}/dispute/${disputeId}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: ['Mission'],
  }),

  getDispute: builder.query({
    query: ({
      missionId,
      disputeId,
    }: {
      missionId: number;
      disputeId: number;
    }) => {
      return `mission/${missionId}/dispute/${disputeId}`;
    },
    providesTags: ['Mission'],
  }),

  getDisputes: builder.query({
    query: (missionId: number) => {
      return `mission/${missionId}/disputes`;
    },
    providesTags: ['Mission'],
  }),

  deleteDispute: builder.mutation({
    query: ({
      missionId,
      disputeId,
    }: {
      missionId: number;
      disputeId: number;
    }) => ({
      url: `mission/${missionId}/dispute/${disputeId}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['Mission'],
  }),
  modificationProposal: builder.mutation({
    query: ({ body }: { body: any }) => ({
      url: `mission/modification-proposal`,
      method: 'POST',
      body,
    }),
    invalidatesTags: ['Mission'],
  }),
  getProposal: builder.query({
    query: ({ id, proposalId }: { id: number; proposalId: number }) => {
      return `/mission/${id}/modification-proposal/${proposalId}`;
    },
  }),
  acceptProposal: builder.mutation({
    query: ({ id, proposalId }: { id: number; proposalId: number }) => ({
      url: `mission/${id}/modification-proposal/${proposalId}/accept`,
      method: 'POST',
    }),
    invalidatesTags: ['Mission'],
  }),
  rejectProposal: builder.mutation({
    query: ({ id, proposalId }: { id: number; proposalId: number }) => ({
      url: `mission/${id}/modification-proposal/${proposalId}/reject`,
      method: 'POST',
    }),
    invalidatesTags: ['Mission'],
  }),
  sprintCheckout: builder.mutation({
    query: ({ id }: { id: number }) => ({
      url: `sprint/checkout/${id}`,
      method: 'POST',
    }),
    invalidatesTags: ['Mission'],
  }),
  transactionCharge: builder.mutation({
    query: ({ id }: { id: number }) => ({
      url: `transaction/charge?id=${id}`,
      method: 'POST',
    }),
    invalidatesTags: ['Mission'],
  }),
  getStripeCheckoutURL: builder.query({
    query: ({
      id,
      token,
      amount,
      product_name,
      product_description,
      quantity,
      payment_method,
    }: {
      id: number;
      token: string;
      amount: number;
      product_name: string;
      product_description: string;
      quantity: number;
      payment_method: string;
    }) => {
      return `/sprint/checkout/${id}/${token}?amount=${amount}&product_name=${product_name}&product_description=${product_description}&quantity=${quantity}&payment_method=${payment_method}`;
    },
  }),
  getProjectByMissionId: builder.query({
    query: (id: string) => {
      return `/mission/${id}/`;
    },
    providesTags: ['Mission'],
  }),
  endMission: builder.mutation({
    query: ({ id }: { id: number }) => ({
      url: `mission/${id}/end`,
      method: 'POST',
    }),
    invalidatesTags: ['Mission'],
  }),
  approveOrRejectMissionEnd: builder.mutation({
    query: ({ id, decision }: { id: number; decision: string }) => ({
      url: `mission/${id}/end/approve-or-reject`,
      method: 'POST',
      body: { decision },
    }),
    invalidatesTags: ['Mission'],
  }),
});

export const missionApi = api.injectEndpoints({ endpoints });
export const {
  useAddMissionMutation,
  useGetAllMissionsQuery,
  useGetMissionByIdQuery,
  useAddDocumentMutation,
  useSignDocumentMutation,
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useLazyGetDocumentQuery,
  useModificationProposalMutation,
  useGetProposalQuery,
  useAcceptProposalMutation,
  useRejectProposalMutation,
  useSprintCheckoutMutation,
  useLazyGetStripeCheckoutURLQuery,
  useGetProjectByMissionIdQuery,
  useAddDisputeMutation,
  useToggleSprintElementDoneMutation,
  useToggleClientApprovalSprintMutation,
  useToggleFreelanceApprovalSprintMutation,
  useCreateDisputeMutation,
  useUpdateDisputeMutation,
  useGetDisputeQuery,
  useGetDisputesQuery,
  useDeleteDisputeMutation,
  useTransactionChargeMutation,
  useEndMissionMutation,
  useApproveOrRejectMissionEndMutation,
} = missionApi;
