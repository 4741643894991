import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Blocks } from 'react-loader-spinner';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';

import { SignUpFormFreelancer } from '../../globals';
import { useSignUpFreelanceMutation } from '../../redux/services/auth';

const RegisterFormInfo3 = ({ onPrev, data, setData }: any) => {
  const [isFirstNameTouched, setIsFirstNameTouched] = useState<boolean>(false);
  const [isLastNameTouched, setIsLastNameTouched] = useState<boolean>(false);
  const [isAddressTouched, setIsAddressTouched] = useState<boolean>(false);
  const [isCityTouched, setIsCityTouched] = useState<boolean>(false);
  const [isTvaTouched, setIsTvaTouched] = useState<boolean>(false);
  const [isSiretTouched, setIsSiretTouched] = useState<boolean>(false);
  const [isTvaValid, setIsTvaValid] = useState<boolean>(false);
  const [isSiretValid, setIsSiretValid] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const navBarHeight = 82.16;
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const [userSignUp] = useSignUpFreelanceMutation();
  const navigate = useNavigate();

  const isValidSIRET = (siret: string): boolean => {
    const siretRegex = /^[0-9]{14}$/;
    return siretRegex.test(siret);
  };

  const validateAddress = (address: string) => {
    return address.trim().length > 0;
  };

  const validateCity = (city: string) => {
    const regex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
    return regex.test(city);
  };

  const isValidTVA = (tvaNumber: string): boolean => {
    const tvaRegex = /^(FR[A-Z0-9]{2}[0-9]{9})$/;
    return tvaRegex.test(tvaNumber);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors: any = {};
    if (!validateAddress(data.address)) newErrors.address = 'Adresse invalide';
    if (!validateCity(data.city)) newErrors.city = 'Ville invalide';
    if (!isValidTVA(data.tva)) newErrors.tva = 'Numéro de TVA invalide';
    if (!isValidSIRET(data.siret)) newErrors.siret = 'Numéro de SIRET invalide';
    if (!data.firstName?.trim()) newErrors.firstName = 'Le prénom est requis';
    if (!data.lastName?.trim()) newErrors.lastName = 'Le nom est requis';

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      // Affiche un toast avec un message d'erreur s'il y a des champs manquants
      toast.error('Veuillez remplir tous les champs requis.');
      return;
    }

    setData({ ...data, loading: true });
    setIsLoad(true);
    return new Promise<string>((resolve, reject) => {
      const formData: SignUpFormFreelancer = {
        address: data.address,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        phone: data.phone,
        siret: data.siret,
        tva_number: data.tva,
        company_name: 'Luter42',
        white_label: data.whiteLabel,
        companyAddress: data.companyAddress,
        dob: data.dob,
        url: data.url,
        mcc: data.mcc,
        business_type: data.businessType,
      };
      userSignUp({ body: formData })
        .unwrap()
        .then((res: any) => {
          setData({ ...data, loading: false });
          toast.success(
            'Vous allez être redirigé vers notre partenaire Stripe pour finaliser votre inscription',
            {
              toastId: 'successSignUp',
              position: 'top-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: true,
              closeButton: false,
              progress: undefined,
              theme: 'light',
            }
          );
          setTimeout(() => {
            window.open(res.stripe_setup_url, '_blank', 'noreferrer');
          }, 5000);

          resolve('Success');
        })
        .catch((err: any) => {
          console.log(err);
          if (err.status === 400) {
            toast.warn(err.data.error, {
              toastId: 'badEmailOrUserAlreadyExists',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          if (err.status === 500) {
            toast.warn(err.data.error, {
              toastId: 'serverError',
              position: 'bottom-right',
            });
          }
          setData({ ...data, loading: false });
          setIsLoad(false);
          reject(err.data.error ? err.data.error : err);
        });
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="flex min-h-screen p-20"
      style={{ height: `calc(100vh - ${navBarHeight}px)` }}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col md:w-2/3 rounded-md pr-10"
      >
        <div className="grid gap-6 mb-6 md:grid-cols-2 py-2 mt-12">
          <div className="mb-4">
            <label htmlFor="firstName" className="block font-bold mb-2">
              Prénom
            </label>
            <input
              type="text"
              name="firstName"
              value={data.firstName}
              placeholder="John"
              onChange={(e) => {
                setData({ ...data, firstName: e.target.value });
                setIsFirstNameTouched(true);
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                isFirstNameTouched && !data.firstName?.trim()
                  ? 'border-red-500'
                  : ''
              }`}
            />
            {errors.firstName && (
              <p className="text-red-500 text-xs italic">{errors.firstName}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block font-bold mb-2">
              Nom
            </label>
            <input
              type="text"
              name="lastName"
              value={data.lastName}
              placeholder="Doe"
              onChange={(e) => {
                setData({ ...data, lastName: e.target.value });
                setIsLastNameTouched(true);
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                isLastNameTouched && !data.lastName?.trim()
                  ? 'border-red-500'
                  : ''
              }`}
            />
            {errors.lastName && (
              <p className="text-red-500 text-xs italic">{errors.lastName}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="address" className="block font-bold mb-2">
              Adresse postale
            </label>
            <input
              type="text"
              name="address"
              value={data.address}
              placeholder="1 rue de la Paix"
              onChange={(e) => {
                setData({ ...data, address: e.target.value });
                setIsAddressTouched(true);
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                isAddressTouched && !data.address?.trim()
                  ? 'border-red-500'
                  : ''
              }`}
            />
            {errors.address && (
              <p className="text-red-500 text-xs italic">{errors.address}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="block font-bold mb-2">
              Ville
            </label>
            <input
              type="text"
              name="city"
              value={data.city}
              placeholder="Paris"
              onChange={(e) => {
                setData({ ...data, city: e.target.value });
                setIsCityTouched(true);
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                isCityTouched && !data.city?.trim() ? 'border-red-500' : ''
              }`}
            />
            {errors.city && (
              <p className="text-red-500 text-xs italic">{errors.city}</p>
            )}
          </div>
        </div>
        <div className="border mx-10 mb-10"></div>
        <div className="mb-4">
          <label htmlFor="tva" className="block font-bold mb-2">
            Numéro de TVA
          </label>
          <p className="text-xs text-gray-500">
            Le numéro de TVA est composé de 13 caractères
          </p>
          <p className="text-xs text-gray-500">Exemple: FRXX XXXX XXXX</p>
          <input
            type="text"
            name="tva"
            value={data.tva}
            placeholder="FRXX XXXX XXXX"
            onChange={(e) => {
              if (e.target.value.length > 13) {
                return;
              }
              setData({ ...data, tva: e.target.value });
              setIsTvaTouched(true);
              setIsTvaValid(isValidTVA(e.target.value));
            }}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              isTvaTouched && !isTvaValid ? 'border-red-500' : ''
            }`}
          />
          {errors.tva && (
            <p className="text-red-500 text-xs italic">{errors.tva}</p>
          )}
          <p className="text-xs text-gray-500">
            {13 - data.tva.length} caractères restants
          </p>
        </div>
        <div className="mb-4">
          <label htmlFor="siret" className="block font-bold mb-2">
            Numéro de SIRET
          </label>
          <p className="text-xs text-gray-500">
            Le numéro de SIRET est composé de 14 chiffres
          </p>
          <p className="text-xs text-gray-500">Exemple: 12345678912345</p>
          <input
            type="text"
            name="siret"
            value={data.siret}
            placeholder="XXXXXXXXXXXXXX"
            onChange={(e) => {
              if (e.target.value.length > 14) {
                return;
              }
              setData({ ...data, siret: e.target.value });
              setIsSiretTouched(true);
              setIsSiretValid(isValidSIRET(e.target.value));
            }}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              isSiretTouched && !isSiretValid ? 'border-red-500' : ''
            }`}
          />
          {errors.siret && (
            <p className="text-red-500 text-xs italic">{errors.siret}</p>
          )}
          <p className="text-xs text-gray-500">
            {14 - data.siret.length} caractères restants
          </p>
        </div>
        {/* <div className="mb-4">
          <label htmlFor="society" className="block font-bold mb-2">
            Code d'accès bêta
          </label>
          <input
            type="text"
            name="society"
            placeholder="Code d'accès"
            onChange={(e) => {
              setData({ ...data, society: e.target.value });
            }}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div> */}

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onPrev}
          >
            Précédent
          </button>
          {isLoad ? (
            <Blocks color="#1552F0" height={30} width={30} />
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Suivant
            </button>
          )}
        </div>
      </form>
      {windowWidth > 768 && (
        <div className="w-full md:w-2/5 pl-4" style={{ height: '100%' }}>
          <BulleForm
            title="Dernière ligne droite !"
            subtitle="Aucun engagement"
            text="Vous êtes libre de modifier à n'importe quel moment vos informations. Si vous souhaitez d'ores et déjà vous renseigner sur les différentes offres de Luter, vous pouvez consulter notre page tarifs."
            buttonText="Consulter nos tarifs"
            buttonLink="/guidance"
          />
        </div>
      )}
    </div>
  );
};

export default RegisterFormInfo3;
