/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import {
  useGetClientByIdQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
} from '../../redux/services/clients';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';

const ClientDetails = () => {
  const user = useSelector((state: any) => state.user.user);
  const [updateClient] = useUpdateClientMutation();
  const [deleteClient] = useDeleteClientMutation();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  if (id === undefined) {
    return <div>404</div>;
  }
  const clientId = parseInt(id, 10);
  const {
    data: clientData,
    isLoading,
    isError,
  } = useGetClientByIdQuery<Record<string, any>>(clientId.toString());
  console.log(clientData);
  const [editableFirstName, setEditableFirstName] = useState(
    clientData?.first_name || ''
  );
  const [editableLastName, setEditableLastName] = useState(
    clientData?.last_name || ''
  );
  const [editableCompanyName, setEditableCompanyName] = useState(
    clientData?.company_name || ''
  );
  const [editableAddress, setEditableAddress] = useState(
    clientData?.address || ''
  );
  const [editablePhone, setEditablePhone] = useState(clientData?.phone || '');
  const [editableEmail, setEditableEmail] = useState(clientData?.email || '');
  const [editableSiret, setEditableSiret] = useState(clientData?.siret || '');
  const [editableTva, setEditableTva] = useState(clientData?.tva_number || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (clientData) {
      setEditableFirstName(clientData.first_name);
      setEditableLastName(clientData.last_name);
      setEditableCompanyName(clientData.company_name);
      setEditableAddress(clientData.address);
      setEditablePhone(clientData.phone);
      setEditableEmail(clientData.email);
      setEditableSiret(clientData.siret);
      setEditableTva(clientData.tva_number);
    }
  }, [clientData]);

  const handleDelete = async () => {
    if (!showDeleteConfirm) {
      setShowDeleteConfirm(true);
      return;
    }

    try {
      displayWarningToast('Suppression du client en cours', 'deletingClient');
      await deleteClient(clientId.toString()).unwrap();
      displaySuccessToast('Client supprimé avec succès', 'deletingClient');
      navigate('/client/');
    } catch (error) {
      displayWarningToast(
        'Erreur lors de la suppression du client',
        'deletingClient'
      );
      console.error(error);
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
  };

  const validateField = (name: string, value: string) => {
    let error = '';

    if (!value.trim()) {
      switch (name) {
        case 'first_name':
          error = 'Le prénom est obligatoire';
          break;
        case 'last_name':
          error = 'Le nom est obligatoire';
          break;
        case 'company_name':
          error = "Le nom de l'entreprise est obligatoire";
          break;
        case 'address':
          error = "L'adresse est obligatoire";
          break;
        case 'phone':
          error = 'Le numéro de téléphone est obligatoire';
          break;
        case 'email':
          error = "L'email est obligatoire";
          break;
        case 'siret':
          error = 'Le numéro SIRET est obligatoire';
          break;
        case 'tva_number':
          error = 'Le numéro de TVA est obligatoire';
          break;
        default:
          error = 'Ce champ est obligatoire';
      }
    } else {
      switch (name) {
        case 'email':
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            error = "Format d'email invalide (exemple: nom@domaine.com)";
          }
          break;
        case 'phone':
          const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
          if (!phoneRegex.test(value)) {
            error = 'Format de téléphone invalide (exemple: 0612345678)';
          }
          break;
        case 'siret':
          const siretRegex = /^\d{14}$/;
          if (!siretRegex.test(value)) {
            error = 'Le SIRET doit contenir exactement 14 chiffres';
          }
          break;
        case 'tva_number':
          const tvaRegex = /^(?:FR)?[0-9A-Z]{2}[0-9]{9}$/;
          if (!tvaRegex.test(value)) {
            error = 'Format de TVA invalide (exemple: FR12345678900)';
          }
          break;
        case 'first_name':
        case 'last_name':
          const nameRegex = /^[a-zA-ZÀ-ÿ\s-]{2,}$/;
          if (!nameRegex.test(value)) {
            error =
              'Le nom doit contenir au moins 2 caractères et uniquement des lettres';
          }
          break;
        case 'company_name':
          if (value.length < 2) {
            error =
              "Le nom de l'entreprise doit contenir au moins 2 caractères";
          }
          break;
        case 'address':
          if (value.length < 5) {
            error = "L'adresse doit contenir au moins 5 caractères";
          }
          break;
      }
    }
    return error;
  };

  const handleBlur = (fieldName: string, value: string) => {
    setTouched((prev) => ({ ...prev, [fieldName]: true }));
    const error = validateField(fieldName, value);
    setErrors((prev) => ({ ...prev, [fieldName]: error }));
  };

  const displayErrorSummary = (errors: Record<string, string>) => {
    const errorMessages = Object.entries(errors)
      .filter(([_, value]) => value)
      .map(([key, value]) => value);

    if (errorMessages.length > 0) {
      const errorList = errorMessages.join('\n- ');
      displayWarningToast(
        `Veuillez corriger les erreurs suivantes:\n- ${errorList}`,
        'formValidation'
      );
    }
  };

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fieldsToValidate = {
      first_name: editableFirstName,
      last_name: editableLastName,
      company_name: editableCompanyName,
      address: editableAddress,
      phone: editablePhone,
      email: editableEmail,
      siret: editableSiret,
      tva_number: editableTva,
    };

    const newErrors: Record<string, string> = {};
    let hasErrors = false;

    Object.entries(fieldsToValidate).forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) {
        newErrors[key] = error;
        hasErrors = true;
      }
    });

    setErrors(newErrors);
    setTouched(
      Object.keys(fieldsToValidate).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      )
    );

    if (hasErrors) {
      displayErrorSummary(newErrors);
      return;
    }

    setIsSubmitting(true);
    const updatedClientDetails = {
      first_name: editableFirstName,
      last_name: editableLastName,
      company_name: editableCompanyName,
      address: editableAddress,
      phone: editablePhone,
      email: editableEmail,
      siret: editableSiret,
      tva_number: editableTva,
    };
    try {
      displayWarningToast('Mise à jour du client en cours', 'updatingClient');
      await updateClient({
        body: updatedClientDetails,
        id: clientId.toString(),
      });
      displaySuccessToast('Client mis à jour avec succès', 'updatingClient');
      navigate('/client/');
    } catch (error) {
      displayWarningToast(
        'Erreur lors de la mise à jour du client',
        'updatingClient'
      );
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInput = (
    fieldName: string,
    value: string,
    label: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    maxLength?: number
  ) => (
    <div className="w-full">
      <p className="text-primary w-full">
        <strong>{label}</strong>
      </p>
      <input
        type="text"
        value={value}
        maxLength={maxLength}
        className={`text-mainBlack w-full rounded px-2 border-2 ${
          touched[fieldName] && errors[fieldName]
            ? 'border-red-500'
            : 'border-lightGrey'
        } bg-fullWhite`}
        onChange={onChange}
        onBlur={() => handleBlur(fieldName, value)}
      />
      {touched[fieldName] && errors[fieldName] && (
        <p className="text-red-500 text-sm mt-1">{errors[fieldName]}</p>
      )}
    </div>
  );

  return (
    <DashboardLayout>
      <form onSubmit={handleUpdate}>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
            Modifier les informations
          </div>
          <Link className="max-[640px]:ml-8" to="/client">
            <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded">
              <AiOutlineArrowLeft size={24} className="mr-2 text-white" />
              Retour
            </button>
          </Link>
        </div>
        {user.user_type == 'client' ? (
          <div className="flex justify-center items-center -mt-36 min-h-screen text-4xl text-primary">
            Vous n&apos;avez pas les droits pour accéder à cette page
          </div>
        ) : (
          <>
            <div className="h-2/3 bg-fullWhite mt-8 p-8 rounded-[30px] border border-whiteGrey shadow-xl overflow-auto md:ml-8 sm:ml-8 max-[640px]:ml-8 text-xl">
              {isLoading ? (
                <div className="animate-pulse">
                  <div className="flex justify-between mb-4">
                    <div className="w-full mr-2">
                      <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded w-full"></div>
                    </div>
                    <div className="w-full ml-2">
                      <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded w-full"></div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded w-full"></div>
                  </div>
                  <div className="mb-4">
                    <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded w-full"></div>
                  </div>
                  <div className="mb-4">
                    <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded w-full"></div>
                  </div>
                  <div style={{ display: 'flex' }} className="justify-between">
                    <div className="w-full mr-2">
                      <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded w-full"></div>
                    </div>
                    <div className="w-full ml-2">
                      <div className="h-6 bg-gray-200 rounded w-1/4 mb-2"></div>
                      <div className="h-10 bg-gray-200 rounded w-full"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{ display: 'flex' }}
                    className="justify-between mb-4"
                  >
                    {renderInput(
                      'first_name',
                      editableFirstName,
                      'Prénom:',
                      (e) => setEditableFirstName(e.target.value),
                      14
                    )}
                    {renderInput(
                      'last_name',
                      editableLastName,
                      'Nom:',
                      (e) => setEditableLastName(e.target.value),
                      13
                    )}
                  </div>
                  {renderInput(
                    'company_name',
                    editableCompanyName,
                    "Nom de l'entreprise:",
                    (e) => setEditableCompanyName(e.target.value)
                  )}
                  {renderInput('address', editableAddress, 'Adresse:', (e) =>
                    setEditableAddress(e.target.value)
                  )}
                  {renderInput(
                    'phone',
                    editablePhone,
                    'Téléphone:',
                    (e) => setEditablePhone(e.target.value),
                    13
                  )}
                  {renderInput('email', editableEmail, 'Email:', (e) =>
                    setEditableEmail(e.target.value)
                  )}
                  <div style={{ display: 'flex' }} className="justify-between">
                    {renderInput(
                      'siret',
                      editableSiret,
                      'SIRET:',
                      (e) => setEditableSiret(e.target.value),
                      14
                    )}
                    {renderInput(
                      'tva_number',
                      editableTva,
                      'Numéro de TVA:',
                      (e) => setEditableTva(e.target.value),
                      13
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-around mt-8 ml-8">
              {showDeleteConfirm ? (
                <div className="flex gap-4">
                  <button
                    className="flex bg-red-500 hover:bg-red-600 text-white py-2 px-4 mt-4 rounded"
                    onClick={handleDelete}
                    disabled={isLoading}
                  >
                    Confirmer la suppression
                  </button>
                  <button
                    className="flex bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 mt-4 rounded"
                    onClick={handleCancelDelete}
                    disabled={isLoading}
                  >
                    Annuler
                  </button>
                </div>
              ) : (
                <button
                  className="flex bg-red-500 hover:bg-red-600 text-white py-2 px-4 mt-4 rounded"
                  onClick={handleDelete}
                  disabled={isLoading}
                >
                  Supprimer
                </button>
              )}
              <button
                type="submit"
                className={`flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mt-4 mr-4 rounded ${
                  (isSubmitting || isLoading) && 'opacity-50 cursor-not-allowed'
                }`}
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting ? 'Mise à jour...' : 'Mettre à jour'}
              </button>
            </div>
          </>
        )}
      </form>
    </DashboardLayout>
  );
};

export default ClientDetails;
