import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
// Icon
import { MdSecurity, MdOutlineNotificationsNone } from 'react-icons/md';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiBillLine, RiDeleteBin6Line } from 'react-icons/ri';
import { TiKeyOutline } from 'react-icons/ti';
import { HiOutlineIdentification } from 'react-icons/hi';
import { GiHamburgerMenu } from 'react-icons/gi';

const SettingsMenuSmall: React.FC = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const buttonVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div className="mt-5">
      <button
        onClick={handleMenuToggle}
        className="block sm:hidden flex bg-primary text-white rounded-[10px] p-2 items-center"
      >
        <GiHamburgerMenu className="w-6 h-6" />
        <span className="text-xl font-extrabold ml-3">MENU</span>
      </button>

      {menuOpen && (
        <motion.div
          initial="hidden"
          animate={menuOpen ? 'visible' : 'hidden'}
          variants={buttonVariants}
          transition={{ duration: 0.3 }}
          className={
            menuOpen ? 'flex flex-col justify-center h-full' : 'hidden'
          }
        >
          <div className="flex flex-col mt-5 justify-center h-full">
            <motion.button
              onClick={() => navigate('/settings/identity')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <HiOutlineIdentification size={30} />
              </span>
              <span className="text-xl font-bold">Identité</span>
            </motion.button>
            <motion.button
              onClick={() => navigate('/settings/security')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <MdSecurity size={30} />
              </span>
              <span className="text-xl font-bold">Sécurité</span>
            </motion.button>
            {/* <motion.button
              onClick={() => navigate('/settings/privacy')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <AiOutlineEyeInvisible size={30} />
              </span>
              <span className="text-xl font-bold">Confidentialité</span>
            </motion.button> */}
            <motion.button
              onClick={() => navigate('/settings/billing')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <RiBillLine size={30} />
              </span>
              <span className="text-xl font-bold">Facturation</span>
            </motion.button>
            <motion.button
              onClick={() => navigate('/settings/notifications')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <MdOutlineNotificationsNone size={30} />
              </span>
              <span className="text-xl font-bold">Notifications</span>
            </motion.button>
            {/* <motion.button
              onClick={() => navigate('/settings/keyAPI')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <TiKeyOutline size={30} />
              </span>
              <span className="text-xl font-bold whitespace-nowrap">
                Clés API
              </span>
            </motion.button> */}
            <motion.button
              onClick={() => navigate('/settings/delete')}
              className="flex items-center space-x-2 text-primary rounded-[30px] p-2"
              variants={buttonVariants}
            >
              <span className="">
                <RiDeleteBin6Line size={30} />
              </span>
              <span className="text-xl font-bold whitespace-nowrap">
                Supprimer
              </span>
            </motion.button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default SettingsMenuSmall;
