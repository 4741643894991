import React from 'react';
import DashboardLayout from '../Layout/DashboardLayout';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { notificationTypes } from '../constants/index';
import {
  useMarkAsReadMutation,
  useDeleteMutation,
} from '../redux/services/notification';
import { BsCheckAll } from 'react-icons/bs';

type Props = {};

const Notifications = (props: Props) => {
  const notifications = useSelector(
    (state: any) => state.user.user.notifications
  );

  let arrayForSort = [...notifications];
  const [markAsRead] = useMarkAsReadMutation();
  const [deleteNotification] = useDeleteMutation();

  const onClick = (notification: any) => {
    markAsRead({ body: { notificationId: notification.id } });
  };

  return (
    <DashboardLayout>
      {arrayForSort?.length > 0 ? (
        <div className="flex flex-col">
          {arrayForSort
            ?.sort((a: any, b: any) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            })
            ?.map((notification: any) => (
              <div
                key={notification.id}
                className="flex gap-2 sm:gap-0 flex-col sm:flex-row items-start sm:items-center justify-between p-4 border-b border-gray-200 relative hover:opacity-70 transition-all duration-75"
              >
                {!notification.isRead ? (
                  <div className="absolute top-2 right-2 rounded-full bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center">
                    1
                  </div>
                ) : (
                  <div className="absolute top-2 right-2 rounded-full text-gray-500 text-xs flex items-center justify-center">
                    <BsCheckAll size={20} />
                    Lu
                  </div>
                )}
                <div className="flex flex-col sm:flex-row items-start sm:items-center sm:w-1/3">
                  <div className="sm:ml-4">
                    <h1 className="font-bold text-mainBlack">
                      {notificationTypes?.[notification.type].title}
                    </h1>
                    <p className="text-gray-500">
                      {notificationTypes?.[notification.type].description}
                    </p>
                  </div>
                </div>
                {notificationTypes?.[notification.type] &&
                  notificationTypes?.[notification.type]?.compFactory(
                    notification
                  )}
                {!notification.isRead && (
                  <button
                    onClick={() => onClick(notification)}
                    className="text-gray-500 text-sm"
                  >
                    Marquer comme lu
                  </button>
                )}
                <button
                  onClick={() =>
                    deleteNotification({
                      body: { notificationId: notification.id },
                    })
                  }
                  className="text-gray-500 text-sm"
                >
                  Supprimer
                </button>
                <div className="text-gray-500 text-sm">
                  {Moment(notification.createdAt).fromNow()}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <h1 className="text-2xl font-bold text-mainBlack">
            Aucune notification
          </h1>
          <p className="text-gray-500">Vous n&apos;avez aucune notification</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Notifications;
