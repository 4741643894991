import { api } from './api';
import { type ClientType } from '../../globals';

const endpoints = (builder: any) => ({
  getAllClients: builder.query({
    query: () => `/client`,
    providesTags: ['Client'],
  }),
  addClient: builder.mutation({
    query: ({ body }: { body: ClientType }) => ({
      url: `/client/`,
      method: 'POST',
      body: body,
    }),
    invalidatesTags: ['Client', 'User'],
  }),
  getClientById: builder.query({
    query: (id: string) => `/client/${id}`,
    providesTags: ['Client'],
  }),
  updateClient: builder.mutation({
    query: ({ id, body }: { id: string; body: ClientType }) => ({
      url: `/client/${id}`,
      method: 'PUT',
      body: body,
    }),
    invalidatesTags: ['Client', 'Mission'],
  }),
  deleteClient: builder.mutation({
    query: (id: string) => ({
      url: `/client/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['Client', 'Mission'],
  }),
});

export const clientApi = api.injectEndpoints({ endpoints });
export const {
  useGetAllClientsQuery,
  useAddClientMutation,
  useGetClientByIdQuery,
  useLazyGetClientByIdQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;
