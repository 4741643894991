import React from 'react';

interface Option {
  name: string;
  green: boolean | string;
  blue: boolean | string;
  purple: boolean | string;
}

const options: Option[] = [
  {
    name: 'Génération de smart contracts',
    green: true,
    blue: true,
    purple: true,
  },
  {
    name: 'Génération du cahier des charges',
    green: true,
    blue: true,
    purple: true,
  },
  { name: 'Séquestration de fonds', green: true, blue: true, purple: true },
  {
    name: 'Gestion des litiges',
    green: false,
    blue: '< 5 000€',
    purple: '> 5 000€',
  },
  {
    name: 'Validation cahier des charges',
    green: false,
    blue: true,
    purple: true,
  },
];

const ComparisonTable: React.FC = () => {
  return (
    <div className="container grid grid-cols-5 font-semibold mx-auto bg-white p-4 rounded-lg">
      <div className="col-span-2" />
      <div className="text-formula-green whitespace-nowrap col-span-1 p-2 text-black text-4xl text-center text-formulaGreen bg-white rounded-lg">
        Green
      </div>
      <div className="text-formula-blue whitespace-nowrap col-span-1 p-2 text-black text-4xl text-center text-formulaBlue bg-white rounded-lg">
        Blue
      </div>
      <div className="text-formula-purple whitespace-nowrap col-span-1 p-2 text-black text-4xl text-center text-formulaPurple bg-white rounded-lg">
        Purple
      </div>
      {options?.map((option, index) => (
        <div
          key={index}
          className={`col-span-5 p-2 rounded transition-all duration-300 ease-in-out ${
            index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
          }`}
        >
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-2">{option.name}</div>
            <div className="col-span-1 text-center">
              {typeof option.green === 'boolean'
                ? option.green
                  ? '✓'
                  : '✗'
                : option.green}
            </div>
            <div className="col-span-1 text-center">
              {typeof option.blue === 'boolean'
                ? option.blue
                  ? '✓'
                  : '✗'
                : option.blue}
            </div>
            <div className="col-span-1 text-center">
              {typeof option.purple === 'boolean'
                ? option.purple
                  ? '✓'
                  : '✗'
                : option.purple}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComparisonTable;
