import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Icon
import { BiTrash } from 'react-icons/bi';
import { MdEdit, MdSave } from 'react-icons/md';
// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';
import EditableField from '../../Components/Dashboard/EditableField';
import { updateUser } from '../../redux/feature/userSlice';

const DEBOUNCE_DELAY = 500;

import { RootState } from '../../redux/store';

const SettingsBilling: React.FC = () => {
  const dummyProfiles = [
    {
      id: 1,
      tarification: '400',
      tokenName: 'Test1',
      keys: [
        ['BTC', '1KGeiN...FW', '18/06/23'],
        ['ETH', '4feERw...SG', '29/02/23'],
      ],
      activeSessions: [
        ['Boursorama Camille', 'FR76 4061 .... .... .... .... ...'],
        ['BNP Paribas Camille', 'FR26 3958 .... .... .... .... ...'],
      ],
    },
  ];

  // useState
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    siret: user.siret,
    tvaNumber: user.tva_number,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [tokenName, setTokenName] = useState(dummyProfiles[0].tokenName);
  const nbOfKeysActives = dummyProfiles[0].keys.length;
  const nombreDeSessionsActives = dummyProfiles[0].activeSessions.length;

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleTokenChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setTokenName(e.target.value);
  };

  const isValidSIRET = (siret: string): boolean => {
    const siretRegex = /^[0-9]{9}?[0-9]{5}$/;
    return siretRegex.test(siret);
  };

  const isValidTVA = (tvaNumber: string): boolean => {
    const tvaRegex = /^(FR[A-Z0-9]{2}[0-9]{9})$/;
    return tvaRegex.test(tvaNumber);
  };

  const handleInputChange =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setState((prevState) => ({ ...prevState, [key]: inputValue }));
    };

  const handleSaveClick = () => {
    const isTVAValid = isValidTVA(state.tvaNumber);
    const isSIRETValid = isValidSIRET(state.siret);

    if (isTVAValid && isSIRETValid) {
      dispatch(
        updateUser({
          ...user,
          siret: state.siret,
          tva_number: state.tvaNumber,
        })
      );
      setIsEditMode(false);
      toast.success('Les données ont été sauvegardées avec succès!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    if (!isTVAValid) {
      toast.error('Numéro de TVA invalide', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      console.error('Numéro de TVA invalide');
    } else if (!isSIRETValid) {
      toast.error('Numéro SIRET invalide', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      console.error('Numéro SIRET invalide');
    }
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left text-mainBlack">
              Paramètres du profil
            </h1>
            {/* <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div> */}
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex overflow-y-auto p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full">
              {/* Section 1 */}
              <div className="w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4 text-mainBlack">
                    Informations générales
                  </h1>
                  {isEditMode ? (
                    <button
                      onClick={handleSaveClick}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdSave className="text-darkCyan" />
                      <p>Sauvegarder</p>
                    </button>
                  ) : (
                    <button
                      onClick={handleEditClick}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdEdit className="text-darkCyan" />
                      <p>Modifier</p>
                    </button>
                  )}
                </div>
                <h3 className="text-blue-500 ml-5">
                  Nous gardons vos données privées et ne les partageons pas avec
                  des tiers
                </h3>
                <hr className="my-2" />
                {/* Mode d'édition */}
                {isEditMode ? (
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <EditableField
                        label="Numéro TVA"
                        value={state.tvaNumber}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('tvaNumber')}
                        placeholder={'FR 0123 456 789'}
                      />
                    </div>
                    <div className="w-1/2">
                      <EditableField
                        label="Numéro SIRET"
                        value={state.siret}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('siret')}
                        placeholder={'365 287 900 36564'}
                      />
                    </div>
                  </div>
                ) : (
                  // Mode de visualisation
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <p className="text-blue-500">Numéro TVA</p>
                      <p className="font-bold text-mainBlack">
                        {user.tva_number}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-blue-500">Numéro SIRET</p>
                      <p className="font-bold text-mainBlack">{user.siret}</p>
                    </div>
                  </div>
                )}
              </div>

              {/* Section 2 */}
              {/* <div className="relative w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="absolute top-0 left-0 w-full h-full bg-gray-300 bg-opacity-50 flex items-center justify-center rounded-[5px] z-50">
                  <span className="text-gray-700 font-bold text-2xl">
                    Bientôt disponible
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Vos banques</h1>
                </div>
                <h3 className="text-blue-500 ml-5">
                  Choisissez votre compte créditeur
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex">
                    {dummyProfiles[0].activeSessions?.map((session, index) => (
                      <div className="ml-10" key={index}>
                        <h2 className="text-lg font-bold">{session[0]}</h2>
                        <p className="text-cyan-700 text-sm">{session[1]}</p>
                      </div>
                    ))}
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-right ml-10"
                      onClick={() => navigate('/')}
                    >
                      + ajouter un compte
                    </button>
                  </div>
                </div>
              </div> */}

              {/* Section 3 */}
              {/* <div className="relative w-full overflow-y-auto border-2 rounded-[5px] p-4 mb-5">
                <div className="absolute top-0 left-0 w-full h-full bg-gray-200 bg-opacity-75 flex items-center justify-center rounded-[5px] z-50">
                  <span className="text-gray-700 font-bold text-2xl">
                    Bientôt disponible
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Vos wallet</h1>
                </div>
                <h3 className="text-blue-500 ml-5">
                  La facilité de l&apos;utilisation des cryptomonnaies
                </h3>
                <hr className="my-2" />
                <div className="flex flex-col mt-10 mx-20 items-center">
                  <div className="flex flex-row items-center">
                    <h2 className="text-cyan-800 ml-10">NOM</h2>
                    <h2 className="text-cyan-800 ml-24">CLÉ</h2>
                    <h2 className="text-cyan-800 ml-24">CRÉÉ LE</h2>
                    <h2 className="text-cyan-800 ml-10">SUPPRIMER</h2>
                  </div>
                </div>
                <hr className="my-2" />
                <div className="flex flex-col mt-10 mx-20 items-center">
                  {dummyProfiles[0].keys?.map((session, index) => (
                    <div className="flex flex-row items-center" key={index}>
                      <h2 className="text-black font-bold bold-2">
                        {session[0]}
                      </h2>
                      <p className="text-black font-bold bold-2 ml-20 text-sm">
                        {session[1]}
                      </p>
                      <p className="text-black font-bold bold-2 ml-20 text-sm">
                        {session[2]}
                      </p>
                      <div className="flex ml-20 items-center">
                        <BiTrash className="cursor-pointer text-red-500 hover:text-red-700" />
                      </div>
                      {index !== nbOfKeysActives - 1 && <hr className="my-2" />}
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsBilling;
