import React from 'react';
import DashboardLayoutClient from '../Layout/DashboardLayoutClient';

type Props = {};

const Dashboard = (props: Props) => {
  const dummyMissions = [
    {
      id: 1,
      title: 'CrabBird',
      description: 'Task 1',
      endDate: '2023-06-01',
      price: 1000,
      currency: 'EUR',
      paymentStatus: 'Paid',
      status: 'Completed',
    },
    {
      id: 2,
      title: 'CrabBird',
      description: 'Task 2',
      endDate: '2023-06-05',
      price: 2000,
      currency: 'EUR',
      paymentStatus: 'Pending',
      status: 'Ongoing',
    },
    {
      id: 3,
      title: 'CrabBird',
      description: 'Task 3',
      endDate: '2023-06-01',
      price: 100,
      currency: 'EUR',
      paymentStatus: 'Paid',
      status: 'Completed',
    },
    {
      id: 4,
      title: 'CrabBird',
      description: 'Task 4',
      endDate: '2023-06-05',
      price: 4000,
      currency: 'EUR',
      paymentStatus: 'Pending',
      status: 'Ongoing',
    },
  ];

  return (
    <DashboardLayoutClient>
      <div className="flex justify-center items-center min-h-screen">
        <div className="h-full mt-4 mb-8 rounded-[30px] border border-gray-200 bg-creamWhite shadow-xl md:ml-8">
          <div className="text-4xl">Mission en cours</div>
          <div className="bg-white rounded-[20px] p-4 mr-8 ml-8 flex-1 relative">
            <div className="h-full mt-4 mb-8 rounded-[30px] border border-gray-200 bg-creamWhite shadow-xl">
              <div className="bg-white rounded-[20px] m-8 p-8">
                <table className="w-full">
                  <thead className="bg-whiteGrey text-primary">
                    <tr>
                      <th className="py-2">MISSIONS</th>
                      <th>TÂCHE</th>
                      <th>DATE LIMITE</th>
                      <th>PROCHAIN PAIEMENT</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {dummyMissions?.map((mission) => (
                      <tr key={mission.id}>
                        <td className="px-4 py-2">{mission.title}</td>
                        <td className="px-4">{mission.description}</td>
                        <td className="px-4">{mission.endDate}</td>
                        <td className="px-4">{`${mission.price} ${mission.currency} (${mission.paymentStatus})`}</td>
                        <td className="px-4">{mission.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayoutClient>
  );
};

export default Dashboard;
