import React, { useMemo, useEffect } from 'react';
import HorizontalArrowRight from '@/assets/Images/horizontal_arrow_right.svg';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import HandDrawnSmile from '@/assets/Images/hand_drawn_smile.svg';
import Arrow from '../../assets/arrow_right.svg';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type Props = {};

interface StepProps {
  number: number;
  titleKey: string;
  descriptionKey: string;
}

const Step = ({ number, titleKey, descriptionKey }: StepProps) => {
  return (
    <div className="relative flex flex-col justify-center items-center max-w-xs">
      <h1 className="text-[162px] z-0 absolute -top-28 text-transparent font-extrabold text-center max-w-2xl bg-clip-text bg-gradient-to-b from-[#A1B5D4] to-dark-gray">
        {number}
      </h1>
      <h1 className="text-2xl md:text-3xl font-extrabold text-center max-w-2xl z-10">
        {titleKey}
      </h1>
      <p className="text-xl md:text-lg text-center text-light-gray max-w-7xl mt-6 z-10 h-36 md:h-44">
        {descriptionKey}
      </p>
    </div>
  );
};

const LitigeSection = (props: Props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const stepList = useMemo(
    () => [
      {
        number: 1,
        titleKey: 'Évaluation',
        descriptionKey:
          "Lorsqu'un litige est déclaré, notre équipe d'assistance technique examine les preuves soumises par les deux parties pour évaluer la situation.",
      },
      {
        number: 2,
        titleKey: 'Médiation',
        descriptionKey:
          "Si le litige ne peut pas être résolu par notre équipe d'évaluation, nous avons des médiateurs disponibles pour aider à négocier un règlement équitable pour les deux parties.",
      },
      {
        number: 3,
        titleKey: 'Arbitrage',
        descriptionKey:
          "Si la médiation échoue, nous proposons une option d'arbitrage pour les clients et les freelances. Cette étape est plus formelle et implique un tiers indépendant qui évalue les preuves et rend une décision.",
      },
    ],
    []
  );

  return (
    <div
      ref={ref}
      className="font-Apfel bg-gradient-to-b from-[#1552F0] via-[#1239A0] to-[#0A1C70] flex flex-col justify-center items-center gap-6 py-24 text-white"
      id="processusAnchor"
    >
      <h1 className="text-4xl md:text-6xl font-extrabold text-center">
        Et en cas de litige ?
      </h1>
      <div className="relative">
        <p
          className="text-2xl lg:text-4xl font-extrabold text-center max-w-7xl mt-6 text-light-gray z-40 relative"
          dangerouslySetInnerHTML={{ __html: 'On le résout !' }}
        ></p>
      </div>
      <div className="flex flex-col lg:flex-row gap-32 lg:gap-0 justify-around items-center mt-24 mb-16 w-full">
        {stepList?.map((step, index) => (
          <div
            key={step.number}
            className="flex flex-col lg:flex-row justify-between items-center gap-24 lg:gap-24 w-128"
          >
            <motion.div
              initial="hidden"
              animate={controls}
              transition={{
                delay: index * 0.5,
                duration: 1,
              }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 },
              }}
            >
              <Step
                number={step.number}
                titleKey={step.titleKey}
                descriptionKey={step.descriptionKey}
              />
            </motion.div>
            {index < stepList?.length - 1 && (
              <motion.div
                ref={ref}
                initial="hidden"
                animate={controls}
                transition={{
                  delay: 0.5 + index * 0.5,
                  duration: 0.5,
                }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 50 },
                }}
              >
                <img
                  className="rotate-90 lg:rotate-0"
                  src={Arrow}
                  alt="arrow"
                  width={100}
                  height={100}
                />
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LitigeSection;
