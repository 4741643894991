/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import DashboardLayoutClient from '../../Layout/DashboardLayoutClient';
import { TextPad } from '../../Components/CreateMissionSteps/TextPad';
import {
  useModificationProposalMutation,
  useSprintCheckoutMutation,
  useGetProjectByMissionIdQuery,
} from '../../redux/services/missions';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';
import {
  useToggleClientApprovalSprintMutation,
  useApproveOrRejectMissionEndMutation,
} from '../../redux/services/missions';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
  FaFlagCheckered,
  FaWallet,
  FaMoneyBill,
  FaEuroSign,
} from 'react-icons/fa';
import Button from '../../Components/Button';

const debounce = (func: any, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const ClientMissionDetails = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [approvedSprints, setApprovedSprints] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modificationProposal] = useModificationProposalMutation();
  const [sprintCheckout] = useSprintCheckoutMutation();
  const [endMission] = useApproveOrRejectMissionEndMutation();
  const [decodedUser, setDecodedUser] = useState<any>(null);
  const [tokenError, setTokenError] = useState<boolean>(false);

  const token =
    new URLSearchParams(location.search).get('token') ||
    useSelector((state: any) => state.user.accessToken);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token as string);
        setDecodedUser(decoded);
      } catch (error) {
        console.error('Invalid token:', error);
        setTokenError(true);
      }
    } else {
      setTokenError(true);
    }
  }, [token]);

  const { data: projectData, refetch } = useGetProjectByMissionIdQuery(
    decodedUser?.projectId,
    {
      skip: !decodedUser?.projectId,
    }
  );

  useEffect(() => {
    const debouncedRefetch = debounce(() => {
      console.log('🔄 Executing refetch after debounce');
      refetch()
        .then(() => console.log('✅ Refetch successful'))
        .catch((error) => console.error('❌ Refetch failed:', error));
    }, 1000);

    const onFocus = () => {
      console.log('👀 Window focused - triggering debounced refetch');
      debouncedRefetch();
    };

    console.log('🎯 Setting up focus listener');
    window.addEventListener('focus', onFocus);

    return () => {
      console.log('🧹 Cleaning up focus listener');
      window.removeEventListener('focus', onFocus);
    };
  }, [refetch]);

  const [toggleClientApprovalSprint] = useToggleClientApprovalSprintMutation();

  useEffect(() => {
    if (!projectData) return;
    setData((projectData as any)[0] || {});
  }, [projectData]);

  const onClickToggleClientApprovalSprint = async (sprintId: number) => {
    setIsLoading(true);
    await toggleClientApprovalSprint({ sprintId })
      .unwrap()
      .then(() => {
        displaySuccessToast('Sprint approuvé !');
        setIsLoading(false);
        setApprovedSprints([...approvedSprints, sprintId]);
      })
      .catch(() => {
        setIsLoading(false);
        displayWarningToast("Erreur lors de l'approbation du sprint !");
      });
  };

  const generateTaskStructure = (sprint: any, paymentMethods: any) => {
    const isSprintDone = sprint.sprintElements.every(
      (task: any) => task.done === true
    );

    const isSprintPaid = sprint.transactions.find(
      (transaction: any) =>
        transaction?.status === 'valid' || transaction?.status === 'captured'
    );

    const isSprintPaidRaw = sprint.transactions.find(
      (transaction: any) => transaction?.status === 'valid'
    );

    const isSprintCaptured = sprint.transactions.find(
      (transaction: any) => transaction?.status === 'captured'
    );

    const isSprintBankPrint = paymentMethods?.includes('bank_print');

    return (
      <div
        className={`flex flex-col gap-4 ${
          isSprintDone ? 'bg-green-100' : ''
        } p-4 rounded-lg flex`}
      >
        <strong className="flex flex-col">
          Intitulé du sprint: {sprint.name}{' '}
          {isSprintDone ? (
            <span className="text-green-500">Terminé</span>
          ) : (
            <span className="text-red-500">En cours...</span>
          )}
        </strong>
        <div>
          <strong>Validation du sprint:</strong>
          <div className="flex justify-start items-center gap-2">
            <p>Freelance:</p>
            {sprint.freelance_approval ? (
              <p className="text-green-500">Approuvé</p>
            ) : (
              <p className="text-red-500">En attente...</p>
            )}
          </div>
          <div className="flex justify-start items-center gap-2">
            <p>Votre approbation:</p>
            {sprint.client_approval ? (
              <p className="text-green-500">Approuvé</p>
            ) : (
              <p className="text-red-500">En attente...</p>
            )}
          </div>
        </div>
        <p>
          <strong>Prix total (TTC):</strong> {sprint.price_ttc}€
        </p>
        {sprint.transactions &&
        sprint?.transactions?.length > 0 &&
        sprint.transactions.find(
          (transaction: any) =>
            transaction.status === 'valid' || transaction.status === 'captured'
        ) ? (
          <p className="text-green-500">Payé</p>
        ) : (
          <>
            <>
              {!isSprintDone && !isSprintBankPrint && (
                <div className="flex gap-2 items-center">
                  <IoIosInformationCircleOutline color="orange" />
                  <p className="text-orange-500">
                    Vous ne pouvez pas régler tant que le freelance n&apos;a pas
                    terminé le sprint
                  </p>
                </div>
              )}
            </>
            <>
              {isSprintBankPrint && (
                <div className="flex gap-2 items-center">
                  <IoIosInformationCircleOutline color="red" />
                  <p className="text-red-500">
                    Le freelance ne peut pas commencer tant que vous n&apos;avez
                    pas réglé le sprint
                  </p>
                </div>
              )}
            </>
            <Button
              onClick={() => handlePayment(sprint)}
              disabled={(!isSprintDone && !isSprintBankPrint) || isLoading}
            >
              <div className="flex items-center justify-center gap-2">
                <FaWallet />
                Payer
              </div>
            </Button>
          </>
        )}
        {!isSprintDone && isSprintCaptured && isSprintBankPrint && (
          <div className="flex gap-2 items-center">
            <IoIosInformationCircleOutline color="blue" />
            <p className="text-blue-500">
              En attente que le freelance finisse le sprint
            </p>
          </div>
        )}
        {(isSprintDone || approvedSprints.includes(sprint?.id)) && (
          <>
            {!isSprintPaid && (
              <div className="flex gap-2 items-center">
                <IoIosInformationCircleOutline color="orange" />
                <p className="text-orange-500">
                  Vous ne pouvez pas approuver tant que vous n&apos;avez pas
                  réglé le sprint
                </p>
              </div>
            )}

            {!isSprintPaid && isSprintPaidRaw && !isSprintBankPrint && (
              <Button
                onClick={() => onClickToggleClientApprovalSprint(sprint?.id)}
                disabled={
                  isLoading ||
                  (!isSprintPaid && isSprintPaidRaw && !isSprintBankPrint)
                }
              >
                Approuver le sprint
              </Button>
            )}
          </>
        )}
        {isSprintCaptured &&
          isSprintBankPrint &&
          !sprint.client_approval &&
          isSprintDone && (
            <Button
              onClick={() => onClickToggleClientApprovalSprint(sprint?.id)}
              disabled={isLoading || !isSprintPaid}
            >
              Approuver le sprint
            </Button>
          )}

        <br />
        <h1>
          <strong>Tâches:</strong>
        </h1>
        <ul>
          {sprint.sprintElements &&
            sprint.sprintElements?.map((task: any, index: number) => (
              <div
                className={`flex flex-row center items-center`}
                key={task?.id}
              >
                <li
                  className={`flex flex-col w-full text-mainBlack gap-4 rounded-xl p-4 ${
                    task.done ? 'bg-green-200' : ''
                  }`}
                >
                  <div className="flex gap-4 justify-between">
                    <p className="font-light text-base">Tâche n°{index + 1}</p>
                    <div className="flex flex-col justify-start items-center">
                      <div className="flex justify-start items-center gap-2">
                        <span className="font-regular text-xs underline">
                          Terminé par le freelance ?
                        </span>{' '}
                        <input
                          className="h-5 w-5"
                          type="checkbox"
                          disabled
                          checked={task.done}
                        />
                      </div>
                      <div className="relative group">
                        <button
                          type="button"
                          className="text-gray-500/40 font-medium text-sm px-5 py-2.5 text-center underline"
                        >
                          Comment ça marche ?
                        </button>
                        <div className="absolute z-10 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-900 text-white p-4 rounded-lg shadow-lg w-96 -translate-x-1/2 left-1/2">
                          <p className="text-sm">
                            Voici comment valider une mission :
                            <br />
                            1. Le freelance valide chaque tâche d&apos;un sprint
                            une fois terminée.
                            <br />
                            2. Le client vérifie et approuve chaque tâche.
                            <br />
                            3. Quand toutes les tâches d&apos;un sprint sont
                            validées :
                            <br />
                            - Le freelance peut approuver le sprint
                            <br />
                            - Le client peut approuver le sprint
                            <br />
                            - Le paiement est déclenché selon la méthode choisie
                            <br />
                            4. Une fois tous les sprints terminés et approuvés,
                            la mission peut être finalisée.
                            <br />
                            Cela garantit un suivi précis et un paiement
                            équitable à chaque étape du projet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="flex flex-col gap-4 justify-between">
                    <p>
                      <span className="font-bold">Prix (TTC):</span>{' '}
                      {task.price_ttc}€
                    </p>
                    <p>
                      <span className="font-bold">Description: </span>
                      {task.description
                        ? task.description
                        : 'Aucune description disponible'}
                    </p>
                  </div>
                </li>
              </div>
            ))}
        </ul>
      </div>
    );
  };

  const handlePayment = async (sprint: any) => {
    await sprintCheckout({ id: sprint.id })
      .unwrap()
      .then(async (res) => {
        const encodedProductName = encodeURIComponent(sprint.name);
        const encodedProductDescription = encodeURIComponent(
          sprint.description
        );
        const encodedPaymentMethods = encodeURIComponent(paymentMethods);

        window.open(
          `${import.meta.env.VITE_BASE_API_URL}/sprint/checkout/${sprint.id}/${
            (res as any).checkout_token
          }?amount=${
            sprint.price_ttc
          }&product_name=${encodedProductName}&product_description=${encodedProductDescription}&quantity=${
            sprint?.sprintElements?.length
          }&payment_method=${encodedPaymentMethods}`,
          '_blank'
        );
        displaySuccessToast('Paiement effectué !');
      })
      .catch((err) => {
        displayWarningToast('Erreur lors du paiement !');
      });
  };
  const numberOfSprints = data?.sprints ? data?.sprints?.length : 0;

  const paymentMethods =
    data?.projectPayments &&
    data?.projectPayments?.map((payment: any) => payment.name).join(', ');

  const totalMissionPrice =
    data?.sprints &&
    data?.sprints.reduce(
      (total: number, sprint: any) => total + sprint.price_ttc,
      0
    );

  const handleSaveEdit = async () => {
    setIsLoading(true);
    modificationProposal({ body: data })
      .unwrap()
      .then((res: any) => {
        displaySuccessToast(
          'Proposition de modification envoyée ! En attente de validation par le freelance.'
        );
      })
      .catch((err) => {
        console.log(err);
        displayWarningToast(
          "Erreur lors de l'envoi de la proposition de modification !"
        );
      });
    setTimeout(() => {
      setIsEditing(!isEditing);
      setIsLoading(false);
    }, 2000);
  };

  const handleChangeEdit = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function getPaymentMethod(paymentMethod: string) {
    if (paymentMethod === 'row') {
      return 'Comptant';
    } else if (paymentMethod === 'bank_print') {
      return 'Empreinte bancaire';
    } else {
      return 'Progressif';
    }
  }

  const handleEndMission = async () => {
    setIsLoading(true);
    await endMission({
      id: data.missionCompletions[0].id,
      decision: 'accepted',
    })
      .unwrap()
      .then(() => {
        console.log('trest');
        displaySuccessToast('Mission terminée !');
        setIsLoading(false);
      })
      .catch(() => {
        console.log('trest 222');
        setIsLoading(false);
        displayWarningToast('Erreur lors de la fin de la mission !');
      });
  };

  const handleCheckout = async () => {
    await sprintCheckout({ id: data.id })
      .unwrap()
      .then(async (res) => {
        // sum of all the sprint prices
        window.open(
          `${import.meta.env.VITE_BASE_API_URL}/sprint/checkout/${data.id}/${
            (res as any).checkout_token
          }?amount=${totalMissionPrice}&product_name=${
            data.name
          }&product_description=${data.description}&quantity=${
            data.sprints.length
          }&payment_method=${paymentMethods}`,
          '_blank'
        );
        displaySuccessToast('Paiement effectué ! La page va se rafraîchir.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setIsLoading(false);
        displayWarningToast('Erreur lors du paiement !');
      });
  };

  const sortSprints = (sprints: any[]) => {
    if (!sprints) return [];

    try {
      return [...sprints].sort((a, b) => {
        if (!a || !b) return 0;

        const idA = a.id ?? 0;
        const idB = b.id ?? 0;

        return idA - idB;
      });
    } catch (error) {
      console.error('Error sorting sprints:', error);
      return sprints;
    }
  };

  return (
    <DashboardLayoutClient>
      <div className="flex flex-col gap-4 container mx-auto sm:max-w-6xl">
        <h1 className="text-3xl text-center font-bold text-mainBlack flex flex-col sm:flex-row justify-between items-center gap-4">
          Informations de la mission
          {/* {isEditing ? (
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                className="rounded-lg w-full disabled:opacity-80 min-w-[200px] bg-primary whitespace-nowrap px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
                onClick={handleSaveEdit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Blocks height="15" width="15" color="white" />
                ) : (
                  <>
                    Confirmer les modifications <FaPen size={20} />
                  </>
                )}
              </button>
              <button
                className="rounded-lg whitespace-nowrap disabled:opacity-80 w-full bg-red-500 px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
                onClick={() => setIsEditing(!isEditing)}
                disabled={isLoading}
              >
                Annuler les modifications <MdEditOff size={20} />
              </button>
            </div>
          ) : (
            <button
              className="rounded-lg bg-primary px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
              onClick={() => setIsEditing(!isEditing)}
            >
              Proposer une modification <FaPen size={10} />
            </button>
          )} */}
        </h1>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-2">
          {data?.status !== 'completed' ? (
            <Button
              onClick={handleEndMission}
              primary
              disabled={
                isLoading ||
                data?.sprints.some(
                  (s: any) => !s.client_approval || !s.freelance_approval
                ) ||
                data?.missionCompletions?.length === 0
              }
            >
              <div className="flex items-center justify-center gap-2">
                <FaFlagCheckered />
                Terminer la mission
              </div>
            </Button>
          ) : null}
          {paymentMethods?.includes('row') && (
            <Button
              onClick={() => handleCheckout()}
              // disabled={!isSprintDone}
              disabled={isLoading}
            >
              <div className="flex items-center justify-center gap-2">
                <FaWallet />
                Payer intégralité de la mission
              </div>
            </Button>
          )}
        </div>
        {isEditing ? (
          <div>
            <div className="my-2 flex flex-col sm:flex-row justify-start items-center gap-2">
              <h2 className="text-2xl font-semibold text-mainBlack w-fit">
                Nom de la mission:
              </h2>
              <input
                type="text"
                placeholder="Nom de la mission"
                className="outline-none bg-white border p-2 rounded-lg"
                onChange={handleChangeEdit}
                name="name"
                value={data.name}
              />
            </div>
            <div className="bg-fullWhite text-mainBlack rounded-lg p-4">
              <p className="flex gap-2 justify-start items-center">
                <strong>Statut:</strong>{' '}
                {data?.status === 'in_progress'
                  ? 'En cours...'
                  : data?.status === 'completed'
                  ? 'Terminé !'
                  : 'N/A'}
                <span
                  className={`w-2 h-2 rounded-full block ${
                    data?.status === 'in_progress'
                      ? 'bg-yellow-500'
                      : data?.status === 'completed'
                      ? 'bg-green-500'
                      : 'bg-red-500'
                  }`}
                ></span>
              </p>
              <p>
                <strong>Nombre de sprints:</strong> {numberOfSprints}
              </p>
              <p>
                <strong>Méthode(s) de paiement:</strong>{' '}
                <select
                  name="payment_method_ids"
                  id="payment_method_ids"
                  className="w-full outline-none bg-creamWhite"
                  onChange={handleChangeEdit}
                  value={data.projectPayments[0].name}
                >
                  <option value="progressive">Facturation progressive</option>
                  <option value="cryptocurrency">Cryptomonnaie</option>
                  <option value="esc">Séquestration de fonds</option>
                  <option value="row">Comptant</option>
                  <option value="bank_print">Empreinte bancaire</option>
                </select>
              </p>
            </div>
            <TextPad data={data} setData={setData} />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col sm:flex-row gap-4 justify-start items-start sm:items-center">
              <h2 className="text-xl font-smibold text-mainBlack my-2 ">
                Nom de la mission: {data?.name}
              </h2>
              <div
                className={`flex gap-2 justify-start items-center h-fit py-1 rounded-full px-3 text-sm w-fit text-mainBlack border-2
                  ${
                    data?.status === 'in_progress'
                      ? 'bg-yellow-500/50 border-yellow-500'
                      : data?.status === 'completed'
                      ? 'bg-green-500/50 border-green-500'
                      : 'bg-red-500/50 border-red-500'
                  }
                    
                `}
              >
                {data?.status === 'in_progress'
                  ? 'En cours...'
                  : data?.status === 'completed'
                  ? 'Terminé !'
                  : 'N/A'}
                <span
                  className={`w-2 h-2 rounded-full block ${
                    data?.status === 'in_progress'
                      ? 'bg-yellow-500'
                      : data?.status === 'completed'
                      ? 'bg-green-500'
                      : 'bg-red-500'
                  }`}
                ></span>
              </div>
            </div>
            <div className="text-mainBlack rounded-lg p-4">
              <div className="flex gap-2 justify-start items-center">
                <FaMoneyBill />
                <strong>Méthode(s) de paiement:</strong>{' '}
                {getPaymentMethod(paymentMethods) || 'N/A'}
              </div>
              <p className="flex gap-2 justify-start items-center">
                <FaEuroSign />
                <strong>Prix total de la mission:</strong>{' '}
                {totalMissionPrice || 0}€
              </p>
            </div>
            <h2 className="text-xl font-smibold text-mainBlack my-2 ">
              Description des différents sprints
            </h2>
            <div className="rounded-lg flex flex-col gap-4">
              <p className="font-light">
                Nombre total de sprints: {numberOfSprints}
              </p>
              {data?.sprints &&
                sortSprints(data.sprints).map((sprint: any, index: any) => (
                  <div className="bg-fullWhite rounded-lg mb-4 p-4" key={index}>
                    {generateTaskStructure(sprint, paymentMethods)}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </DashboardLayoutClient>
  );
};

export default ClientMissionDetails;
