import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BiChevronUp } from 'react-icons/bi';

type DropDownMenuProps = {
  label: string;
  handleMenuClick: (label: string) => void;
  expandedLabel: string | null;
  visible: boolean;
};

const DropDownMenu: React.FC<DropDownMenuProps> = ({
  label,
  expandedLabel,
  handleMenuClick,
  visible,
}) => {
  const isExpanded = label === expandedLabel;
  const isAnyLabelExpanded = expandedLabel !== null;
  let navLinks: JSX.Element[] = [];
  const navLinkClassName =
    'text-md text-gray-700 hover:text-gray-900 font-semibold hover:bg-gray-100 p-3 rounded-3xl transition duration-500 inline-block'; // Ensure hover only affects the text
  switch (label) {
    case 'Formules':
      navLinks = [
        <NavLink key="Offres" to="/tarification" className={navLinkClassName}>
          Offres
        </NavLink>,
        <NavLink
          key="Formulaire d'orientation"
          to="/guidance"
          className={navLinkClassName}
        >
          Orientation
        </NavLink>,
      ];
      break;
    case 'À propos':
      navLinks = [
        <NavLink
          key="Description"
          to="/description"
          className={navLinkClassName}
        >
          Description
        </NavLink>,
        <NavLink key="About" to="/about" className={navLinkClassName}>
          L&apos;équipe
        </NavLink>,
        <NavLink key="Timeline" to="/timeline" className={navLinkClassName}>
          Timeline
        </NavLink>,
      ];
      break;
    default:
      break;
  }

  return (
    <div>
      <div key={label}>
        <span
          className="text-black flex justify-between items-center cursor-pointer"
          onClick={() => handleMenuClick(label)}
        >
          <motion.p className={'text-md font-medium'}>{label}</motion.p>
          <span className={'hover:rotate-90 transition-all duration-300'}>
            <BiChevronUp size={26} style={{ color: 'black' }} />
          </span>
        </span>
        {isExpanded && visible && (
          <div
            style={{
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            className="absolute bg-white w-full left-0 top-full z-50 grid grid-cols-2 lg:grid-cols-4 gap-4 py-2 px-64"
          >
            {navLinks?.map((link, index) => (
              <div key={index} className="inline-block">
                {link}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDownMenu;
