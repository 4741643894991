import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { FiLinkedin, FiFacebook, FiGithub } from 'react-icons/fi';
import { FaXTwitter } from 'react-icons/fa6';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { GoPeople } from 'react-icons/go';
import { BsGeoAlt } from 'react-icons/bs';

// Components
import DashboardLayout from '../../Layout/DashboardLayout';

const Profile: React.FC = () => {
  const dummyProfiles = [
    {
      id: 1,
      firstname: 'John',
      lastname: 'Doe',
      fullname: 'John Doe',
      job: 'Développeur Web',
      city: 'Paris',
      time: 'août 2023',
      experience: '5',
      tarification: '400',
      linkTwitter: 'https://twitter.com',
      linkGithub: 'https://github.com',
      linkLinkedin: 'https://linkedin.com',
      linkFacebook: 'https://facebook.com',
    },
  ];
  // useState
  const navigate = useNavigate();
  const [infoText, setInfoText] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);

  // Dumb handle onClick
  const handleMouseEnter = () => {
    setInfoText(
      "La charte permet d'assurer au client de vérifier que vous avez sauvegardé vos données."
    );
  };
  const handleMouseLeave = () => {
    setInfoText('');
  };
  const handleMouseEnter2 = () => {
    setInfoText('Informations supplémentaires');
  };
  const handleMouseLeave2 = () => {
    setInfoText('');
  };
  const handleMouseEnter3 = () => {
    setInfoText('Informations supplémentaires');
  };
  const handleMouseLeave3 = () => {
    setInfoText('');
  };
  const handleAvailableClick = () => {
    setIsAvailable(!isAvailable);
  };

  const containerStyle = {
    border: '2px solid ' + (isAvailable ? 'green' : 'red'),
    borderRadius: '32px',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    cursor: 'pointer',
  };

  const dotStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: isAvailable ? 'green' : 'red',
    marginRight: '10px',
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-4xl font-bold bold-4 w-1/2 text-left">
              Profil
            </h1>
            <div className="w-1/2 text-right"></div>
          </div>
          <div className="flex h-full overflow-y-auto shadow-2xl rounded-[30px] p-6">
            {/* Partie droite de la div */}
            <div className="flex-grow">
              <div className="flex flex-row">
                <div className="space-x-4 mb-5 items-center">
                  <div className="w-200 h-200 rounded-full overflow-hidden">
                    <img
                      src={'../src/assets/randomPP.svg'}
                      alt="Profile"
                      className="w-full h-full object-cover object-center"
                    />
                  </div>
                </div>
                <div className="mx-10 w-full">
                  <div className="mt-4 flex justify-between">
                    <div className="flex flex-col sm:w-2/5 xs:w-full">
                      <h1 className="font-bold text-4xl mb-3">
                        {dummyProfiles[0].fullname}
                      </h1>
                      <h2 className="font-bold text-2xl text-cyan-900 mb-3">
                        {dummyProfiles[0].job}
                      </h2>
                      <div className="flex text-blue-500 space-x-2 text-xl">
                        <BsGeoAlt className="mt-1" />
                        <h2>{dummyProfiles[0].city}</h2>
                      </div>
                      <div className="flex text-blue-500 space-x-2 text-xl xs:hidden sm:block">
                        <GoPeople className="mt-1" />
                        <h2>Membre depuis {dummyProfiles[0].time}</h2>
                      </div>
                      <div className="flex flex-row">
                        <div className="mt-5 w-full overflow-y-auto border-2 rounded-[5px] p-2">
                          <p>Expérience</p>
                          <h2 className="text-center font-bold text-xl mt-3">
                            {dummyProfiles[0].experience} ans
                          </h2>
                        </div>
                        <div className="mt-5 w-full overflow-y-auto border-2 rounded-[5px] p-2">
                          <p>Tarif moyen</p>
                          <h2 className="text-center font-bold text-xl mt-3">
                            {dummyProfiles[0].tarification} / jour
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col xs:hidden sm:block">
                      <div style={containerStyle}>
                        <div style={dotStyle}></div>
                        <p
                          className={
                            isAvailable
                              ? 'text-green-500 text-md'
                              : 'text-red-500 text-md'
                          }
                        >
                          {isAvailable ? 'Disponible' : 'Indisponible'}
                        </p>
                      </div>
                      <button
                        className="mt-40 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => navigate('/')}
                      >
                        Contacter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Section Description */}
              <div className="mt-5 w-full overflow-y-auto border-2 rounded-[5px] p-4">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Description</h1>
                </div>
                <hr className="my-2" />
                <div className="flex mt-10">
                  <div className="space-y-5 mx-5">
                    <p className="text-cyan-700">
                      Mon expérience comprend la création d&apos;applications
                      web, de services distribués et de solutions de traitement
                      de données en utilisant Haskell. Je suis également à
                      l&apos;aise avec des frameworks et des bibliothèques
                      couramment utilisés dans l&apos;écosystème Haskell, tels
                      que Yesod, Scotty, et Aeson. Au delà de ma maîtrise
                      technique, je suis un collaborateur fiable, communicatif
                      et axé sur les résultats. J&apos;ai l&apos;habitude de
                      travailler en équipe, de collaborer avec d&apos;autres
                      développeurs et de m&apos;adapter aux exigences du projet.
                      Je suis également ouvert aux nouvelles idées et à
                      l&apos;apprentissage continu pour rester à jour avec les
                      évolutions du langage Haskell et les meilleures pratiques
                      du secteur.
                    </p>
                  </div>
                </div>
              </div>

              <div className="sm:flex sm:flex-row">
                {/* Section Verif */}
                {/* <div className="mt-5 mr-5 w-full overflow-y-auto border-2 rounded-[5px] p-4">
                  <div className="flex items-center justify-between">
                    <h1 className="font-bold text-3xl mt-4">Vérifications</h1>
                  </div>
                  <h3 className="text-cyan-900 ml-5">
                    Veillez à ce que vos vérifications soient toujours à jour
                  </h3>
                  <hr className="my-5" />
                  <div className="flex space-x-4 justify-center">
                    <div className="bg-green-200 rounded-2xl p-4 w-3/4 flex justify-between items-center">
                      <div className="flex flex-row">
                        <h2 className="font-bold text-gray-700 text-lg">
                          Charte du freelance Luter signée
                        </h2>
                        <span className="text-green-700 rounded-full ml-2">
                          ✓
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="cursor-pointer bg-transparent hover:bg-cyan-500 text-cyan-700 font-semibold hover:text-white border border-cyan-500 hover:border-transparent rounded px-2 py-1">
                          Consulter la charte
                        </div>
                        <div className="text-cyan-700 hover:text-cyan-900 ml-2 relative">
                          <AiOutlineInfoCircle
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          />
                          {infoText && (
                            <div className="z-90 info-tooltip absolute bg-white p-2 rounded border border-gray-300 mt-2">
                              {infoText}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 justify-center mt-5">
                    <div className="bg-green-200 rounded-2xl p-4 w-3/4 flex justify-between items-center">
                      <div className="flex flex-row">
                        <h2 className="font-bold text-gray-700 text-lg">
                          Entreprise validée
                        </h2>
                        <span className="text-green-700 rounded-full ml-2">
                          ✓
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="cursor-pointer bg-transparent hover:bg-cyan-500 text-cyan-700 font-semibold hover:text-white border border-cyan-500 hover:border-transparent rounded px-2 py-1">
                          Vérifiez votre entreprise
                        </div>
                        <div className="text-cyan-700 hover:text-cyan-900 ml-2 relative">
                          <AiOutlineInfoCircle
                            onMouseEnter={handleMouseEnter2}
                            onMouseLeave={handleMouseLeave2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 justify-center mt-5">
                    <div className="bg-red-200 rounded-2xl p-4 w-3/4 flex justify-between items-center">
                      <div className="flex flex-row">
                        <h2 className="font-bold text-gray-700 text-lg">
                          Documents d’identité à jour
                        </h2>
                        <span className="text-red-700 rounded-full ml-2">
                          ✗
                        </span>
                      </div>
                      <div className="flex items-center">
                        <div className="cursor-pointer bg-transparent hover:bg-cyan-500 text-cyan-700 font-semibold hover:text-white border border-cyan-500 hover:border-transparent rounded px-2 py-1">
                          Vérifiez vos documents
                        </div>
                        <div className="text-cyan-700 hover:text-cyan-900 ml-2 relative">
                          <AiOutlineInfoCircle
                            onMouseEnter={handleMouseEnter3}
                            onMouseLeave={handleMouseLeave3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="flex flex-col">
                  {/* Section Reseaux  */}
                  <div className="mt-5 w-full overflow-y-auto border-2 rounded-[5px] p-4">
                    <div className="flex items-center justify-between">
                      <h1 className="font-bold text-3xl mt-4">
                        Réseaux sociaux
                      </h1>
                    </div>
                    <hr className="my-2" />
                    <div className="flex space-x-4 justify-center">
                      <div className="w-2/2">
                        <div className="flex space-x-5 justify-center m-5">
                          <a
                            href={dummyProfiles[0].linkLinkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiLinkedin
                              size={40}
                              className="cursor-pointer text-black hover:text-blue-300 -ml-5"
                            />
                          </a>
                          <a
                            href={dummyProfiles[0].linkTwitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaXTwitter
                              size={40}
                              className="cursor-pointer text-black hover:text-blue-300"
                            />
                          </a>
                          <a
                            href={dummyProfiles[0].linkFacebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiFacebook
                              size={40}
                              className="cursor-pointer text-black hover:text-blue-300"
                            />
                          </a>
                          <a
                            href={dummyProfiles[0].linkGithub}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FiGithub
                              size={40}
                              className="cursor-pointer text-black hover:text-blue-300"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Section Langues  */}
                  <div className="mt-5 w-full overflow-y-auto border-2 rounded-[5px] p-4">
                    <div className="flex items-center justify-between">
                      <h1 className="font-bold text-3xl mt-4">Langues</h1>
                    </div>
                    <hr className="my-2" />
                    <div className="flex mt-10">
                      <div className="w-1/2 space-y-5 text-left mx-5">
                        <p className="text-cyan-700 text-xl font-bold">
                          Français
                        </p>
                        <p className="text-cyan-700 text-xl font-bold">
                          Anglais
                        </p>
                        <p className="text-cyan-700 text-xl font-bold">
                          Mandarin
                        </p>
                      </div>
                      <div className="w-1/2 space-y-5 text-right ">
                        <p className="text-cyan-900 text-xl">Natif</p>
                        <p className="text-cyan-900 text-xl">Professionnel</p>
                        <p className="text-cyan-900 text-xl">Professionnel</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Profile;
