import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './Pages/App';
import Tarification from './Pages/Vitrine/Tarification';
import Login from './Pages/Authentification/Login';
import Register from './Pages/Authentification/RegisterFreelance';
import EmailRecovery from './Pages/Authentification/Password/EmailRecovery';
import EmailSent from './Pages/Authentification/Password/EmailSent';
import ChangePassword from './Pages/Authentification/Password/ChangePassword';
import PasswordChanged from './Pages/Authentification/Password/PasswordChanged';
import Dashboard from './Pages/Dashboard';
import './styles.css';
import { ToastContainer } from 'react-toastify';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import DashboardClient from './Pages/DashboardClient';
import ClientCheckoutSuccess from './Pages/Client/ClientCheckoutSuccess';
import InfoFreelance from './Components/InfoFreelance';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import MissionList from './Pages/Mission/MissionList';
import MissionDetails from './Pages/Mission/MissionDetails';
import MissionDocuments from './Pages/Mission/MissionDocuments';
import MissionDocument from './Pages/Mission/MissionDocument';
import MissionNewDocument from './Pages/Mission/MissionNewDocument';
import MissionDisputes from './Pages/Mission/MissionDisputes';
import MissionDispute from './Pages/Mission/MissionDispute';
import MissionNewDispute from './Pages/Mission/MissionNewDispute';
import CreateMission from './Pages/Mission/CreateMission';

import ModificationProposalDetail from './Pages/Mission/ModificationProposalDetail';

import CreateClient from './Pages/Client/CreateClient';
import ClientList from './Pages/Client/ClientList';
import ClientDetails from './Pages/Client/ClientDetails';
import UpdateClient from './Pages/Client/UpdateClient';

import ClientMissionDetails from './Pages/ClientsJourney/ClientMissionDetails';

import ClientDashboard from './Pages/Client/Mission/ClientDashboard';
import GuidanceForm from './Pages/Vitrine/GuidanceForm';

import DeclarationScreen from './Pages/DeclarationScreen';

import DeclarationSimulatorScreen from './Pages/Declaration/DeclarationSimulatorScreen';
import DeclarationUrssafScreen from './Pages/Declaration/DeclarationUrssafScreen';

import Notifications from './Pages/Notifications';

import { AutoLoginRedirect } from './Components/AutoLoginRedirect';

import Profile from './Pages/Profile/Profile';

// Settings
import SettingsID from './Pages/Settings/SettingsID';
import SettingsSecurity from './Pages/Settings/SettingsSecurity';
import SettingsPrivacy from './Pages/Settings/SettingsPrivacy';
import SettingsBilling from './Pages/Settings/SettingsBilling';
import SettingsNotif from './Pages/Settings/SettingsNotif';
import SettingsKeyAPI from './Pages/Settings/SettingsKeyAPI';
import SettingsDelete from './Pages/Settings/SettingsDelete';

// Vitrine
import Timeline from './Pages/Vitrine/Timeline';
import About from './Pages/Vitrine/About';
import Description from './Pages/Vitrine/Description';
import Contact from './Pages/Vitrine/Contact';
import LegalNotices from './Pages/Vitrine/LegalNotices';
import PrivacyPolicy from './Pages/Vitrine/PrivacyPolicy';
import ConditionsSale from './Pages/Vitrine/ConditionsSale';

import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import WebSocketListener from './socket/socketListener';

import { DarkModeProvider } from './context/DarkContext';
import Transactions from './Pages/Client/Transactions';

const ErrorComponent = () => (
  <div>
    <h1>Oops! Something went wrong!</h1>
    <p>Sorry, there was an error with the page you are trying to access.</p>
  </div>
);

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider);
  //   library.pollingInterval = 12000;
  return library;
};

const ProtectedRoute = ({ children }: any) => {
  const isLogged = useSelector((state: any) => state.user.isLogged);
  if (!isLogged) return <Navigate to="/login" replace />;
  return children;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/tarification',
    element: <Tarification />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/autoLoginRedirect',
    element: <AutoLoginRedirect />,
  },
  {
    path: '/reset-password/email-recovery',
    element: <EmailRecovery />,
  },
  {
    path: '/email-sent',
    element: <EmailSent />,
  },
  {
    path: '/reset-password/:resetToken',
    element: <ChangePassword />,
  },
  {
    path: '/reset-password/password-changed',
    element: <PasswordChanged />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/dashboard/client',
    element: <DashboardClient />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/settings/identity',
    element: <SettingsID />,
  },
  {
    path: '/settings/security',
    element: <SettingsSecurity />,
  },
  {
    path: '/notifications',
    element: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    path: '/declaration/simulation',
    element: (
      <ProtectedRoute>
        <DeclarationSimulatorScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/declaration',
    element: (
      <ProtectedRoute>
        <DeclarationScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/declaration/urssaf',
    element: (
      <ProtectedRoute>
        <DeclarationUrssafScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/settings/privacy',
    element: <SettingsPrivacy />,
  },
  {
    path: '/settings/billing',
    element: <SettingsBilling />,
  },
  {
    path: '/settings/notifications',
    element: <SettingsNotif />,
  },
  {
    path: '/settings/keyAPI',
    element: <SettingsKeyAPI />,
  },
  {
    path: '/settings/delete',
    element: <SettingsDelete />,
  },
  {
    path: '/mission',
    element: (
      <ProtectedRoute>
        <MissionList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id',
    element: (
      <ProtectedRoute>
        <MissionDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/documents',
    element: (
      <ProtectedRoute>
        <MissionDocuments />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/document/:documentId',
    element: (
      <ProtectedRoute>
        <MissionDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/document/new',
    element: (
      <ProtectedRoute>
        <MissionNewDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/dispute/new',
    element: (
      <ProtectedRoute>
        <MissionNewDispute />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/disputes',
    element: (
      <ProtectedRoute>
        <MissionDisputes />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/dispute/:disputeId',
    element: (
      <ProtectedRoute>
        <MissionDispute />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mission/:id/modification-proposal/:proposalId',
    element: <ModificationProposalDetail />,
  },

  {
    path: '/mission/new',
    element: (
      <ProtectedRoute>
        <CreateMission />
      </ProtectedRoute>
    ),
  },
  {
    path: '/client/new',
    element: (
      <ProtectedRoute>
        <CreateClient />
      </ProtectedRoute>
    ),
  },
  {
    path: '/client/',
    element: (
      <ProtectedRoute>
        <ClientList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/client/freelance',
    element: <InfoFreelance />,
  },
  {
    path: '/client/:id',
    element: <ClientDetails />,
  },
  {
    path: '/client/:id/update',
    element: <UpdateClient />,
  },
  {
    path: '/client/dashboard',
    element: <ClientDashboard />,
  },
  {
    path: '/client/checkout-success',
    element: <ClientCheckoutSuccess />,
  },
  {
    path: '/client/mission/details',
    element: <ClientMissionDetails />,
  },
  {
    path: '/client/transactions',
    element: <Transactions />,
  },
  {
    path: '/guidance',
    element: <GuidanceForm />,
  },
  {
    path: '/timeline',
    element: <Timeline />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/description',
    element: <Description />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/legal-notices',
    element: <LegalNotices />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/conditions-sale',
    element: <ConditionsSale />,
  },
  {
    path: '*',
    element: <ErrorComponent />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <WebSocketListener />
          <DarkModeProvider>
            {/* <ThemeProvider theme={theme}> */}
            <RouterProvider router={router} />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* </ThemeProvider> */}
          </DarkModeProvider>
        </PersistGate>
      </Provider>
    </Web3ReactProvider>
  </StrictMode>
);
