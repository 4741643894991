import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface PaymentChartData {
  sprint: string;
  payment: number;
}

interface PaymentChartProps {
  data: PaymentChartData[];
}

const PaymentChart: React.FC<PaymentChartProps> = ({ data }) => {
  const chartOptions: any = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#4285F4'],
    },
    xaxis: {
      categories: data?.map((item) => item.sprint),
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    markers: {
      size: 4,
      colors: ['#4285F4'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `€${val}`;
        },
      },
    },
    title: {
      text: 'Paiements',
      align: 'left',
      style: {
        fontSize: '16px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'bold',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Paiement',
      data: data?.map((item) => item.payment),
    },
  ];

  return (
    <div className="w-full text-black">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default PaymentChart;
