import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface IncomeChartData {
  month: string;
  year: string;
  income: number;
}

interface IncomeChartProps {
  data: IncomeChartData[];
}

const IncomeChart: React.FC<IncomeChartProps> = ({ data }) => {
  // Ensure that data is not empty to avoid runtime errors
  const year = data.length > 0 ? data[0].year : 'N/A';

  // Define chart options with dynamic title
  const chartOptions: any = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#4285F4'],
    },
    xaxis: {
      categories: data?.map((item) => `${item.month}`),
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
        },
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: '#4285F4',
          width: 1,
          dashArray: 0,
        },
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 400,
          color: '#4285F4',
        },
      },
      labels: {
        style: {
          colors: ['#4285F4'],
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    markers: {
      size: 4,
      colors: ['#4285F4'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `€${val}`;
        },
      },
    },
    title: {
      text: year === 'N/A' ? 'Pas encore de revenus' : `Revenus en ${year}`,
      align: 'left',
      style: {
        fontSize: '16px',
        fontFamily: 'Roboto, sans-serif',
        color: '#4285F4',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Revenus',
      data: data?.map((item) => item.income),
    },
  ];

  return (
    <div className="w-full text-black">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default IncomeChart;
