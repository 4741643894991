import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer';

const ConditionsSale: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="font-Apfel min-h-screen">
        <div className="container mx-auto px-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-3xl font-bold mb-4 mt-20">
              Conditions générales de vente
            </h1>

            <h2 className="text-xl font-bold mb-2">Préambule</h2>
            <p className="text-secondary mb-4 leading-relaxed">
              Les présentes Conditions Générales de Vente ont pour objet de
              régir le contrat conclu entre Luter et le Client désigné par le
              devis ou la proposition commerciale, ainsi que le(s) éventuel(s)
              avenant(s) annexé(s). La signature du devis ou de la proposition
              commerciale avec mention d'accord emporte acceptation sans réserve
              des présentes Conditions Générales de Vente qui s'appliquent quels
              que soient les options, les modules souscrits et les modalités des
              travaux à réaliser. Les éventuelles conditions particulières
              figurant sur le devis priment sur les présentes Conditions
              Générales de Vente.
            </p>

            <h2 className="text-xl font-bold mb-2">Article 1 : Généralités</h2>
            <p className="text-secondary mb-4 leading-relaxed">
              Le Client faisant appel aux services de Luter reconnaît avoir pris
              connaissance et accepté sans réserve les Conditions Générales de
              Vente suivantes. Pour ce faire, le Client apposera lors d'une
              première commande sa signature précédée de la mention manuscrite «
              lu et approuvé » au bas du devis établi par Luter au nom du
              Client.
            </p>

            <h2 className="text-xl font-bold mb-2">
              Article 2 : Objet / Délais
            </h2>
            <p className="text-secondary mb-4 leading-relaxed">
              Le présent contrat a pour objet de définir les conditions et
              modalités selon lesquelles Luter met ses services, et ceux qu'elle
              pourrait développer à l'avenir, à disposition de ses Clients. Les
              délais d'exécution, indiqués en jours ouvrables, sont fixés à
              réception, datée et signée, du devis ou de la proposition
              commerciale et, si éventuelles corrections d'auteur ou de la durée
              de relecture. Nous ne pouvons être tenus pour responsables d'un
              éventuel retard dans leur réalisation.
            </p>

            <h2 className="text-xl font-bold mb-2">
              Article 3 : Engagement des parties
            </h2>
            <p className="text-secondary mb-4 leading-relaxed">
              Chacune des parties s'engage à collaborer activement pour le bon
              déroulement du contrat et à communiquer pour garantir l'avancée du
              projet et l'aboutissement de la mission. Le Client s'engage à
              fournir à Luter un cahier des charges ou brief détaillé, qui ne
              sera plus modifié une fois approuvé par les deux parties. Le cas
              échéant, un nouveau devis pourra être proposé au Client. Le client
              s'engage également à fournir au prestataire tous les éléments
              documentaires, graphiques et textuels nécessaires à l'exécution de
              la prestation.
            </p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ConditionsSale;
