import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer';

const MentionsLegales: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="font-Apfel min-h-screen">
        <div className="container mx-auto px-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-3xl font-bold mb-4 mt-20">Mentions Légales</h1>
            <p className="text-secondary text-lg mb-8 leading-relaxed">
              Les présentes mentions légales régissent l'utilisation du site Web
              accessible à l'adresse
              <span className="font-bold"> https://www.luter.fr</span>, désigné
              ici comme le « site web ».
            </p>

            <h2 className="text-xl font-bold mb-2">Éditeur du Site</h2>
            <p className="text-secondary mb-4">
              L'éditeur du site est <span className="font-bold">Luter</span>,
              Société par actions simplifiée au capital de 10 000 euros, ayant
              son siège à <span className="font-bold">Lyon</span>. Immatriculée
              au Registre du commerce et des sociétés sous le numéro .
            </p>
            <p className="text-secondary mb-4">
              Adresse de contact :{' '}
              <span className="font-bold">contact.luter@gmail.com</span>
            </p>
            <p className="text-secondary mb-4">
              Téléphone : <span className="font-bold">01.23.45.67.89</span>
            </p>

            <h2 className="text-xl font-bold mb-2">
              Responsable de la publication
            </h2>
            <p className="text-secondary mb-4">
              Le responsable de la publication du site est{' '}
              <span className="font-bold">M. Daniel Tirrier</span>.
            </p>

            <h2 className="text-xl font-bold mb-2">Hébergement</h2>
            <p className="text-secondary mb-4">
              L'hébergement du site est assuré par{' '}
              <span className="font-bold">OVH</span>, 2 rue Kellermann, 59100
              Roubaix, France.
            </p>

            <h2 className="text-xl font-bold mb-2">Propriété intellectuelle</h2>
            <p className="text-secondary mb-4">
              Tous les contenus présents sur ce site sont la propriété exclusive
              de <span className="font-bold">Luter</span>. Toute reproduction
              totale ou partielle sans autorisation expresse est interdite.
            </p>

            <h2 className="text-xl font-bold mb-2">Protection des données</h2>
            <p className="text-secondary mb-4">
              Nous collectons des données personnelles conformément à la
              législation en vigueur. Ces données sont utilisées uniquement pour
              améliorer notre service. Vous pouvez exercer vos droits d'accès,
              de rectification et de suppression de vos données en nous
              contactant.
            </p>

            <h2 className="text-xl font-bold mb-2">Limite de responsabilité</h2>
            <p className="text-secondary mb-4">
              Le site est fourni sans garantie d'aucune sorte. Nous ne pouvons
              être tenus responsables des pertes ou dommages découlant de
              l'utilisation du site.
            </p>

            <h2 className="text-xl font-bold mb-2">Cookies</h2>
            <p className="text-secondary mb-4">
              Nous utilisons des cookies pour analyser le trafic sur notre site.
              Vous pouvez refuser l'utilisation de cookies en modifiant les
              paramètres de votre navigateur.
            </p>

            <h2 className="text-xl font-bold mb-2">Droit applicable</h2>
            <p className="text-secondary mb-4">
              Tout litige relatif à l'utilisation du site est soumis au droit
              français. Les tribunaux compétents sont ceux de Paris.
            </p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MentionsLegales;
