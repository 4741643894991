import { useEffect, useState } from 'react';
import DashboardLayoutClient from '../../../Layout/DashboardLayoutClient';
import { Link, useLocation } from 'react-router-dom';
import { useVerifyClientPublicTokenMutation } from '../../../redux/services/auth';
import { Blocks } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import {
  updateAccessToken,
  updateUser,
} from '../../../redux/feature/userSlice';
import { useDispatch } from 'react-redux';
import PaymentChart from '../../../Components/PaymentChart';
import { useGetProjectByMissionIdQuery } from '../../../redux/services/missions';
import { jwtDecode } from 'jwt-decode';

interface PaymentChartData {
  sprint: string;
  payment: number;
}

const ClientDashboard = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const publicToken =
    searchParams.get('token') ||
    useSelector((state: any) => state.user.accessToken);

  const isClientLogged = useSelector((state: any) => state.user.isClientLogged);
  const [verifyClientPublicToken, { isLoading, isError }] =
    useVerifyClientPublicTokenMutation();
  const dispatch = useDispatch();
  useEffect(() => {
    !isClientLogged &&
      verifyClientPublicToken(publicToken)
        .unwrap()
        .then((res: any) => {
          dispatch(updateUser(res.user));
        });
    if (publicToken !== null) {
      dispatch(updateAccessToken(publicToken));
    }
  }, [publicToken, verifyClientPublicToken]);

  const prepareChartData = (data: any) => {
    // Map through the data and transform each item into the format required by the chart
    const chartData = data?.map((item: any) => {
      return {
        sprint: item.name, // Name of the sprint for x-axis
        payment: item.price_ttc, // Price TTC for y-axis
      };
    });

    return chartData;
  };

  const [mission, setMission] = useState<any>(null);

  useEffect(() => {
    const extractUserInfoFromToken = () => {
      const token = new URLSearchParams(location.search).get('token');
      if (token) {
        const decodedUser = jwtDecode(token); // Call jwtDecode directly
        setMission(decodedUser);
      }
    };

    extractUserInfoFromToken();
  }, []);

  const { data: projectData, isLoading: loading2 } =
    useGetProjectByMissionIdQuery(mission?.projectId);

  const [ChartData, setChartData] = useState<PaymentChartData[]>([]);

  useEffect(() => {
    if (projectData) {
      setChartData(prepareChartData((projectData as any[])[0]?.sprints));
    }
  }, [projectData]);

  if (isLoading || !projectData) {
    return (
      <DashboardLayoutClient>
        <div className="flex justify-center items-center min-h-screen">
          <Blocks color="#2563EB" height={50} width={50} />
        </div>
      </DashboardLayoutClient>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center min-h-screen flex-col">
        <h1 className="text-xl font-bold text-red-500">
          Une erreur est survenue lors de la récupération des données du projet.
        </h1>
        <br />
        <h1 className="text-xl font-bold" style={{ color: '#2563EB' }}>
          Veuillez réessayer plus tard.
        </h1>
      </div>
    );
  }

  return (
    <DashboardLayoutClient>
      <div className="shadow-2xl bg-creamWhite rounded-[30px] p-6 flex flex-col gap-4 border border-whiteGrey">
        <h1 className="text-3xl font-bold text-mainBlack">Dashboard</h1>
        <div className="bg-fullWhite rounded-lg p-4 flex-1 relative">
          <PaymentChart data={ChartData} />
        </div>
      </div>
    </DashboardLayoutClient>
  );
};

export default ClientDashboard;
