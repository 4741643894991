import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateClientWork,
  updateClientParticular,
  updateClientLuter,
} from '../../redux/feature/formSlice';
import { FormStateType } from '../../redux/feature/formSlice';
import FormulaCalculator from './FormulaCalculator';
import PopUp from '../PopUp';
import ProgressBar from '../ProgressBar';
import ButtonModern from '../ButtonModern';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

function InfoClient({ onNext, onPrev }: any) {
  const dispatch = useDispatch();
  const {
    price,
    duration,
    tarificationHonnete,
    delaisCohérents,
    confiance,
    missions,
    techno,
    clientTravail,
    clientParticulier,
    clientLuter,
  } = useSelector((state: any) => state.form) as FormStateType;
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isNumberValid = (value: string) => {
    const numberPattern = /^[0-9]+$/;
    return numberPattern.test(value);
  };

  const handleClientWorkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateClientWork(e.target.value));
  };

  const handleClientParticularChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateClientParticular(e.target.value));
  };

  const handleClientLuterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateClientLuter(e.target.value));
  };

  const handleNext = () => {
    if (clientTravail && clientParticulier && clientLuter) {
      onNext();
    } else {
      setShowPopup(true);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Champs manquants"
          text="Veuillez répondre à toutes les questions pour continuer"
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row p-30"
        style={{ height: '100%', padding: 30, paddingTop: '110px' }}
      >
        <div className="font-Apfel w-full md:w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent={99} className="sticky top-0 z-20" />
          <div className="flex items-center justify-between mt-10">
            <button
              onClick={onPrev}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
            >
              Retour
            </button>
            <div className="flex-1 flex ml-10">
              <h1 className="text-4xl font-bold">Informations sur le client</h1>
            </div>
          </div>
          <div className="flex flex-col gap-6 pt-10 justify-center items-center h-full">
            <div className="w-full md:w-3/5 pr-4 justify-center relative flex flex-col">
              <div className="flex flex-col gap-5 mt-10">
                {/* PARTIE TRAVAILLE */}
                <div className="flex flex-col gap-3">
                  <p className="text-xl font-light">
                    Avez-vous déjà travaillé avec ce client?
                  </p>
                  <div className="flex flex-row gap-3">
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        clientTravail === 'Oui'
                          ? 'border-primary'
                          : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Oui"
                          checked={clientTravail === 'Oui'}
                          onChange={handleClientWorkChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Oui</span>
                      </div>
                    </label>
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        clientTravail === 'Non'
                          ? 'border-primary'
                          : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Non"
                          checked={clientTravail === 'Non'}
                          onChange={handleClientWorkChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Non</span>
                      </div>
                    </label>
                  </div>
                </div>

                {/* PARTIE PARTICULIER */}
                <div className="flex flex-col gap-3">
                  <p className="text-xl font-light">
                    Le client est-il un particulier ?
                  </p>
                  <div className="flex flex-row gap-3">
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        clientParticulier === 'Oui'
                          ? 'border-primary'
                          : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Oui"
                          checked={clientParticulier === 'Oui'}
                          onChange={handleClientParticularChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Oui</span>
                      </div>
                    </label>
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        clientParticulier === 'Non'
                          ? 'border-primary'
                          : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Non"
                          checked={clientParticulier === 'Non'}
                          onChange={handleClientParticularChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Non</span>
                      </div>
                    </label>
                  </div>
                </div>

                {/* PARTIE LUTERISATION */}
                <div className="flex flex-col gap-3">
                  <p className="text-xl font-light">
                    Le client a-t-il déjà utilisé Luter ?
                  </p>
                  <div className="flex flex-row gap-3">
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        clientLuter === 'Oui' ? 'border-primary' : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Oui"
                          checked={clientLuter === 'Oui'}
                          onChange={handleClientLuterChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Oui</span>
                      </div>
                    </label>
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        clientLuter === 'Non' ? 'border-primary' : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Non"
                          checked={clientLuter === 'Non'}
                          onChange={handleClientLuterChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Non</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20 flex items-center justify-between">
              <button
                onClick={onNext}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
        <div className="mt-10"></div>
        {windowWidth > 1280 && (
          <div className="flex flex-col h-full ml-20">
            <h2 className="mt-20 text-2xl text-center font-bold">
              Formule en temps réel
            </h2>
            <div className="flex-grow mt-10">
              <FormulaCalculator
                price={price}
                duration={duration}
                tarificationHonnete={tarificationHonnete}
                delaisCohérents={delaisCohérents}
                confiance={confiance}
                missions={missions}
                techno={techno}
                clientTravail={clientTravail}
                clientParticulier={clientParticulier}
                clientLuter={clientLuter}
              />
            </div>
            <div className="flex-none mt-auto"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoClient;
