import React, { useState } from 'react';
import FormulaStep from '../../Components/CreateMissionSteps/FormulaStep';
import PaymentMethod from '../../Components/CreateMissionSteps/PaymentMethod';
import MissionStep from '../../Components/CreateMissionSteps/MissionStep';
import DashboardLayout from '../../Layout/DashboardLayout';
import type { MissionInfo } from '../../globals';
import { useAddMissionMutation } from '../../redux/services/missions';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';
import {
  redirect,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

function CreateMission() {
  const [step, setStep] = useState('formula');
  const location = useLocation();
  const [searchParams] = useSearchParams();
  console.log(searchParams.get('formula'));
  const initialData = {
    formula: searchParams.get('formula') || '',
    payment_method_ids: [],
    name: '',
    start_date: '',
    end_date: '',
    client_id: '',
    status: 'in_progress',
    sprints: [],
  };
  const [data, setData] = useState<MissionInfo>(
    location.state ? location.state.data : initialData
  );
  const navigate = useNavigate();
  const [addMission, { isLoading, error, data: mission }] =
    useAddMissionMutation();

  const handleNext = () => {
    let newState = '';
    switch (step) {
      case 'formula':
        newState = 'paymentMethod';
        break;
      case 'paymentMethod':
        newState = 'missionStep';
        break;
      case 'missionStep':
        displayWarningToast('Mission en cours de création');
        const body = {
          ...data,
          payment_method_ids: [data.payment_method_ids],
          end_date: new Date(data.end_date).toISOString(),
          start_date: new Date(data.start_date).toISOString(),
        };

        addMission({ body })
          .unwrap()
          .then((res: any) => {
            displaySuccessToast('Mission créée avec succès');
            navigate(`/mission`);
          })
          .catch((err) => {
            displayWarningToast('Erreur lors de la création de la mission');
          });
        return;
      default:
        if (data) newState = 'missionStep';
        else newState = 'formula';
    }
    setStep(newState);
  };
  const handlePrevious = () => {
    let newState = '';
    switch (step) {
      case 'paymentMethod':
        newState = 'formula';
        break;
      case 'missionStep':
        newState = 'paymentMethod';
        break;
      case 'checkup':
        newState = 'missionStep';
        break;
      default:
        newState = 'formula';
    }
    setStep(newState);
  };

  return (
    <DashboardLayout>
      {step === 'formula' && (
        <FormulaStep
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {step === 'paymentMethod' && (
        <PaymentMethod
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {step === 'missionStep' && (
        <MissionStep
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          loading={isLoading}
        />
      )}
    </DashboardLayout>
  );
}

export default CreateMission;
