import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import Table from '../../Components/molecules/Table';
import { AiFillEye } from 'react-icons/ai';
import { useMemo } from 'react';
import { useGetDisputesQuery } from '../../redux/services/missions';
import moment from 'moment';

const MissionDisputes = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: disputes } = useGetDisputesQuery(parseInt(id as string), {
    refetchOnMountOrArgChange: true,
  });

  const disputeList = useMemo(() => {
    if (!disputes || !Array.isArray(disputes)) {
      return [];
    }

    return disputes?.map((dispute) => {
      const row = [
        dispute.name,
        dispute.description,
        moment(dispute.disputeDate).format('DD/MM/YYYY'),
        dispute.priority,
      ];

      const actions = [
        {
          label: <AiFillEye size={24} />,
          onClick: () => navigate(`/mission/${id}/dispute/${dispute.id}`),
        },
      ];

      return {
        row,
        actions,
      };
    });
  }, [disputes, id, navigate]);

  return (
    <DashboardLayout>
      <div className="flex justify-between items-center">
        <h2 className="text-mainBlack text-2xl font-semibold">
          Litiges de la mission
        </h2>
        <button
          onClick={() => navigate(`/mission/${id}/dispute/new`)}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
        >
          <span>Ajouter un litige</span>
        </button>
      </div>
      <div className="mt-6">
        <Table
          columns={[
            'Nom',
            'Description',
            'Date du Litige',
            'Priorité',
            'Actions',
          ]}
          data={disputeList}
        />
      </div>
    </DashboardLayout>
  );
};

export default MissionDisputes;
