import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateConfidence,
  updateMissions,
  updateTechno,
} from '../../redux/feature/formSlice';
import { FormStateType } from '../../redux/feature/formSlice';
import FormulaCalculator from './FormulaCalculator';
import PopUp from '../PopUp';
import ProgressBar from '../ProgressBar';
import FeelingInput from './FeelingInput';
import ButtonModern from '../ButtonModern';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

function InfoFreelance({ onNext, onPrev }: any) {
  const dispatch = useDispatch();
  const {
    price,
    duration,
    tarificationHonnete,
    delaisCohérents,
    confiance,
    missions,
    techno,
    clientTravail,
    clientParticulier,
    clientLuter,
  } = useSelector((state: any) => state.form) as FormStateType;
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isNumberValid = (value: string) => {
    const numberPattern = /^[0-9]+$/;
    return numberPattern.test(value);
  };

  const handleConfidenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateConfidence(e.target.value));
  };

  const handleMissionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateMissions(e.target.value));
  };

  const handleTechnoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTechno(e.target.value));
  };

  const handleNext = () => {
    if (techno && missions) {
      onNext();
    } else {
      setShowPopup(true);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Champs manquants"
          text="Veuillez répondre à toutes les questions pour continuer"
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row p-30"
        style={{ height: '100%', padding: 30, paddingTop: '110px' }}
      >
        <div className="font-Apfel w-full md:w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent={66} className="sticky top-0 z-20" />
          <div className="flex items-center justify-between mt-10">
            <button
              onClick={onPrev}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
            >
              Retour
            </button>
            <div className="flex-1 flex ml-10">
              <h1 className="text-4xl font-bold">
                Informations sur le freelance
              </h1>
            </div>
          </div>
          <div className="flex flex-col gap-6 pt-10 justify-center items-center h-full">
            <div className="w-full md:w-3/5 pr-4 justify-center relative flex flex-col">
              {/* PARTIE CONFIANCE */}
              <div className="flex flex-col gap-6">
                <div className="flex flex-col md:flex-row gap-3">
                  <div className="flex-row mr-10">
                    <p className="text-1xl font-light mb-2">
                      Votre confiance sur la mission ?
                    </p>
                    <FeelingInput />
                  </div>
                  {/* PARTIE NB MISSION */}
                  <label className="flex flex-col text-1xl font-light">
                    Nombre de missions réalisées
                    <div className="flex items-center text-2xl">
                      <input
                        type="text"
                        value={missions}
                        onChange={handleMissionsChange}
                        className="mt-1 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
                      />
                      {isNumberValid(missions) ? (
                        <span className="text-green-700 p-2 rounded-full ml-2">
                          ✓
                        </span>
                      ) : (
                        <span className="text-red-700 p-2 rounded-full ml-2">
                          ✗
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>

              <div className="flex flex-row gap-5 mt-10">
                {/* PARTIE TARIFICATION */}
                <div className="flex flex-col gap-3">
                  <p className="text-1xl font-light">
                    Utilisez-vous vos technologies habituelles ?
                  </p>
                  <div className="flex flex-row gap-3">
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        techno === 'Oui' ? 'border-primary' : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Oui"
                          checked={techno === 'Oui'}
                          onChange={handleTechnoChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Oui</span>
                      </div>
                    </label>
                    <label
                      className={`bg-gray-50 border-2 rounded-md text-xl block xs:w-1/2 md:w-2/5 p-2.5 ${
                        techno === 'Non' ? 'border-primary' : 'border-gray'
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Non"
                          checked={techno === 'Non'}
                          onChange={handleTechnoChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Non</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20 flex items-center justify-between">
              <button
                onClick={handleNext}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 transform hover:scale-110"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
        <div className="mt-10"></div>
        {windowWidth > 1280 && (
          <div className="flex flex-col h-full ml-20">
            <h2 className="mt-20 text-2xl text-center font-bold">
              Formule en temps réel
            </h2>
            <div className="flex-grow mt-10">
              <FormulaCalculator
                price={price}
                duration={duration}
                tarificationHonnete={tarificationHonnete}
                delaisCohérents={delaisCohérents}
                confiance={confiance}
                missions={missions}
                techno={techno}
                clientTravail={clientTravail}
                clientParticulier={clientParticulier}
                clientLuter={clientLuter}
              />
            </div>
            <div className="flex-none mt-auto"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoFreelance;
