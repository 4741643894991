import React, { useState } from 'react';
import DashboardLayoutClient from '../../Layout/DashboardLayoutClient';
import { useSelector } from 'react-redux';
import { useGetMissionByIdQuery } from '../../redux/services/missions';
import { BiDollar, BiReceipt, BiFile } from 'react-icons/bi';
import { Blocks } from 'react-loader-spinner';
import { jwtDecode } from 'jwt-decode';

interface Transaction {
  id: number;
  amount: number;
  currency: string;
  charge_id: string;
  payment_intent_id: string;
  status: string;
}

interface Sprint {
  transactions: Transaction[];
  id: number;
  name: string;
  description: string;
  price_ttc: number;
  price_ht: number;
  quantity: number;
  sprints_id: number;
  projects_id: number;
}

interface ProjectMission {
  sprints?: Sprint[]; // Rendre sprints optionnel pour éviter les erreurs si non présent
}

const TransactionDetail = ({
  transaction,
  sprint,
}: {
  transaction: Transaction;
  sprint: Sprint;
}) => {
  return (
    <div>
      <h3 className="text-lg font-bold">
        Sprint {sprint.id} - {sprint.name}
      </h3>
      <p>
        <strong>ID de la transaction :</strong> {transaction.id}
      </p>
      <p>
        <strong>Description :</strong> {sprint.description}
      </p>

      <p>
        <strong>Montant :</strong> {transaction.amount} {transaction.currency}
      </p>
      <p>
        <strong>Status :</strong> {transaction.status}
      </p>
      <p>
        <strong>ID Charge :</strong> {transaction.charge_id}
      </p>
      <p>
        <strong>ID Intent de Paiement :</strong> {transaction.payment_intent_id}
      </p>
    </div>
  );
};

const Transactions = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const token = new URLSearchParams(location.search).get('token');
  const decodedUser: any = jwtDecode(token as string);
  const { data, isLoading, isError } = useGetMissionByIdQuery(
    decodedUser.projectId
  );
  const { accessToken } = useSelector((state: any) => state.user);
  const [downloadingStates, setDownloadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  if (isLoading) {
    return (
      <DashboardLayoutClient>
        <div className="flex justify-center items-center min-h-screen">
          <Blocks color="#2563EB" height={50} width={50} />
        </div>
      </DashboardLayoutClient>
    );
  }
  if (isError) return <p>Error</p>;

  const projectMission: ProjectMission[] = data as ProjectMission[];

  const filteredTransactions =
    projectMission &&
    projectMission[0]?.sprints
      ?.flatMap((sprint) => sprint.transactions)
      .filter((transaction) => {
        return transaction.status
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

  const handleDownload = async (
    id: number,
    type: 'invoice' | 'receipt' | 'terms',
    url: string
  ) => {
    const stateKey = `${type}_${id}`;
    setDownloadingStates((prev) => ({ ...prev, [stateKey]: true }));

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: 'Bearer ' + accessToken,
        },
      });
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${type}-${id}.pdf`;
      a.click();
    } catch (error) {
      console.error(`Error downloading ${type}:`, error);
    } finally {
      setDownloadingStates((prev) => ({ ...prev, [stateKey]: false }));
    }
  };

  const downloadInvoice = (id: number) =>
    handleDownload(
      id,
      'invoice',
      `${import.meta.env.VITE_BASE_API_URL}/transaction/stripe-bill/${id}`
    );

  const downloadReceipt = (id: number) =>
    handleDownload(
      id,
      'receipt',
      `${import.meta.env.VITE_BASE_API_URL}/mission/${
        decodedUser.projectId
      }/bill`
    );

  const downloadTerms = (id: number) =>
    handleDownload(
      id,
      'terms',
      `${import.meta.env.VITE_BASE_API_URL}/mission/${
        decodedUser.projectId
      }/terms`
    );

  return (
    <DashboardLayoutClient>
      <div className="shadow-2xl bg-creamWhite rounded-[30px] min-h-[79vh] p-6 flex flex-col gap-4 border border-whiteGrey">
        <h1 className="text-3xl text-center font-bold text-mainBlack">
          Transactions de la mission
        </h1>
        <input
          type="text"
          placeholder="Rechercher par statut..."
          className="p-2 border border-gray-300 rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isLoading && (
          <div className="flex justify-center items-center min-h-screen flex-col">
            <h1 className="text-xl font-bold" style={{ color: '#2563EB' }}>
              Chargement de vos transactions...
            </h1>
          </div>
        )}
        {filteredTransactions && !isLoading ? (
          filteredTransactions?.map((transaction: Transaction) => {
            return (
              <div
                className="bg-white p-4 rounded-lg shadow"
                key={transaction.id}
              >
                <TransactionDetail
                  transaction={transaction}
                  sprint={
                    projectMission[0]?.sprints?.find((sprint) =>
                      sprint.transactions.includes(transaction)
                    ) as Sprint
                  }
                />
                <div className="flex justify-between">
                  <button
                    onClick={() => downloadInvoice(transaction.id)}
                    disabled={
                      transaction.status === 'in_waiting' ||
                      downloadingStates[`invoice_${transaction.id}`]
                    }
                    className="flex items-center justify-center mt-4 bg-primary text-white p-2 rounded-lg disabled:bg-primary/50"
                  >
                    {downloadingStates[`invoice_${transaction.id}`] ? (
                      <Blocks color="white" height={24} width={24} />
                    ) : (
                      <>
                        <BiDollar size={24} className="mr-2" />
                        <span>Télécharger la facture</span>
                      </>
                    )}
                  </button>

                  <button
                    onClick={() => downloadReceipt(transaction.id)}
                    disabled={downloadingStates[`receipt_${transaction.id}`]}
                    className="flex items-center justify-center mt-4 bg-primary text-white p-2 rounded-lg disabled:bg-primary/50"
                  >
                    {downloadingStates[`receipt_${transaction.id}`] ? (
                      <Blocks color="white" height={24} width={24} />
                    ) : (
                      <>
                        <BiReceipt size={24} className="mr-2" />
                        <span>Télécharger le devis</span>
                      </>
                    )}
                  </button>

                  <button
                    onClick={() => downloadTerms(transaction.id)}
                    disabled={downloadingStates[`terms_${transaction.id}`]}
                    className="flex items-center justify-center mt-4 bg-primary text-white p-2 rounded-lg disabled:bg-primary/50"
                  >
                    {downloadingStates[`terms_${transaction.id}`] ? (
                      <Blocks color="white" height={24} width={24} />
                    ) : (
                      <>
                        <BiFile size={24} className="mr-2" />
                        <span>Télécharger les conditions</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p>Aucune transaction trouvée.</p>
        )}
      </div>
    </DashboardLayoutClient>
  );
};

export default Transactions;
