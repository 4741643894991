import React, { useMemo, useEffect } from 'react';

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Icons GIF
import gif_accompagnement from '../../assets/gif_accompagnement.gif';
import gif_security from '../../assets/gif_security.gif';
import gif_timer from '../../assets/gif_timer.gif';

type Props = {};

interface ThreeGifSectionProps {
  titleKey: string;
  descriptionKey: string;
  GIF: string;
}

const FeatureElement = ({
  titleKey,
  descriptionKey,
  GIF,
}: ThreeGifSectionProps) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 250);
    }
    if (!inView) {
      setTimeout(() => {
        controls.start('hidden');
      }, 250);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
      className="flex flex-col justify-center items-center gap-4"
    >
      <img src={GIF} alt="Magic Wand" className="w-24" />
      <h1 className="text-xl font-bold text-center max-w-4xl">{titleKey}</h1>
      <p className="text-center max-w-3xl text-dark-gray">{descriptionKey}</p>
    </motion.div>
  );
};

const ThreeGifSection = (props: Props) => {
  const featureList = useMemo(
    () => [
      {
        titleKey: "La tranquillité d'esprit assurée",
        descriptionKey:
          'Nous utilisons les dernières technologies de cryptage et de protection des données pour vous assurer une sécurité optimale.',
        GIF: gif_security,
      },
      {
        titleKey: "Focus sur l'essentiel",
        descriptionKey:
          'Invitez vos clients, signez le cahier des charges et confiez-nous le reste.',
        GIF: gif_timer,
      },
      {
        titleKey: 'Soyez maître de votre travail',
        descriptionKey:
          "Profitez d'un espace partagé avec votre client et d'un accompagnement adapté pour chaque échange.",
        GIF: gif_accompagnement,
      },
    ],
    []
  );

  return (
    <div className="relative px-10 font-Apfel">
      <div className="flex flex-col justify-around items-center gap-16">
        <div className="relative">
          <h1
            className="text-4xl md:text-6xl font-extrabold text-center max-w-4xl z-10 overflow-visible"
            dangerouslySetInnerHTML={{
              __html:
                "Un seul compagnon pour tous vos <span class='relative'><span id='handDrawnCircle' class='relative z-10'>besoins</span></span>",
            }}
          ></h1>
        </div>
        <p className="text-center text-xl max-w-3xl text-dark-gray mb-24">
          Chez Luter, nous travaillons chaque jour pour offrir un service
          d&apos;échange de fonds et de prestation de services sécurisé et
          optimisé pour les freelances et leurs clients.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center w-full gap-12 relative md:flex-row mb-24">
        {featureList?.map((feature, index) => (
          <FeatureElement key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default ThreeGifSection;
