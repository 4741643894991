import React from 'react';
import GoogleCalendarConnectButton from './molecules/GoogleCalendarConnectButton';
import { useNavigate } from 'react-router-dom';
import { BiFolder } from 'react-icons/bi';
import Button from './Button';

function renderStatus(status: string) {
  console.log(status);
  switch (status) {
    case 'completed':
      return (
        <div className="bg-green-300 bg-opacity-40 text-green-900 rounded-md w-fit text-center p-2">
          Terminé
        </div>
      );
    case 'cancelled':
      return (
        <div className="bg-red-300 bg-opacity-40 text-red-900 rounded-md w-fit text-center p-2">
          Annulé
        </div>
      );
    default:
      return (
        <div className="bg-orange-300 bg-opacity-40 text-orange-900 rounded-md w-fit text-center p-2">
          En Cours
        </div>
      );
  }
}

function renderPaymentNames(payments: any) {
  return (
    <div className="flex flex-col sm:flex-row items-start">
      <span className="text-sm font-semibold pb-1">
        {payments?.length > 1 ? 'Méthodes ' : 'Méthode '}
        de paiement:{' '}
      </span>
      {payments?.map(
        (payment: { name: string }, index: React.Key | null | undefined) => (
          <span key={index} className="text-sm">
            {payment.name === 'progressive'
              ? 'Progressif'
              : payment.name === 'cryptocurrency'
              ? 'Cryptomonnaie'
              : payment.name === 'esc'
              ? 'Escrow'
              : payment.name === 'row'
              ? 'Comptant'
              : payment.name === 'bank_print'
              ? 'Empreinte bancaire'
              : ''}{' '}
            {index !== payments?.length - 1 ? ', ' : ''}
          </span>
        )
      )}
    </div>
  );
}

function priceCalculator(sprints: any) {
  let total = 0;
  sprints?.length &&
    sprints.forEach((sprint: { price_ttc: number }) => {
      total += sprint.price_ttc;
    });
  return (
    <div className="flex gap-1">
      <span className="text-sm font-semibold">Total: </span>
      <p className="text-sm">{total} €</p>
    </div>
  );
}

type MissionHeaderProps = {
  project: any;
  status: string;
  eventGoogleCalendar: any;
  payments: any;
  sprints: any;
  id: number;
};

const MissionHeader = ({
  project,
  status,
  eventGoogleCalendar,
  payments,
  sprints,
  id,
}: MissionHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="mt-8 z-50 w-full">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end w-full py-2 gap-2">
        <div className="flex flex-col sm:flex-row justify-start items-start sm:items-end gap-4">
          <div className="flex flex-col">
            <h2 className="text-sm text-gray-500">Détails de la mission</h2>
            <h2 className="text-mainBlack text-2xl font-semibold">
              {project?.name}
            </h2>
            <h2 className="text-sm text-gray-500">
              Dates de la mission : du{' '}
              {new Date(project?.start_date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}{' '}
              au{' '}
              {new Date(project?.end_date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </h2>
          </div>
          <div className="flex flex-col bg-opacity-40">
            {renderStatus(status)}
          </div>
        </div>
        <div className="flex flex-col xl:flex-row gap-4">
          <Button onClick={() => navigate('/mission/' + id + '/documents')}>
            <BiFolder className="mr-1" />
            Accéder aux documents
          </Button>
          <GoogleCalendarConnectButton
            purpose="synchronize"
            event={eventGoogleCalendar}
          />
        </div>
      </div>
      <hr className="bg-white w-full" />
      <div className="flex flex-col justify-start items-start py-4 gap-4">
        <div>
          <h2 className="text-sm text-gray-500">Paiement et facturation</h2>
          <div className="flex flex-col justify-start items-start gap-2 text-mainBlack">
            {renderPaymentNames(payments)}
            {priceCalculator(sprints)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionHeader;
