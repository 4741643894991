import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className="font-Apfel min-h-screen">
        <div className="container mx-auto px-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-3xl font-bold mb-4 mt-20">
              Politique de confidentialité
            </h1>

            <h2 className="text-xl font-bold mb-2">
              Collecte de l'information
            </h2>
            <p className="text-secondary mb-4">
              Nous recueillons des informations lorsque vous visitez notre site
              et lorsque vous soumettez des formulaires pour l'établissement
              d'un devis. Les informations recueillies incluent votre nom,
              prénom, adresse e-mail et numéro de téléphone. En outre, nous
              recevons et enregistrons automatiquement des informations à partir
              de votre ordinateur et navigateur, y compris votre adresse IP, vos
              logiciels et votre matériel, et la page que vous demandez.
            </p>

            <h2 className="text-xl font-bold mb-2">
              Utilisation des informations
            </h2>
            <p className="text-secondary mb-4">
              Toutes les informations que nous recueillons auprès de vous
              peuvent être utilisées pour :
              <ul className="list-disc list-inside mb-4">
                <li>
                  Personnaliser votre expérience et répondre à vos besoins
                  individuels.
                </li>
                <li>Fournir un contenu publicitaire personnalisé.</li>
                <li>Améliorer notre site.</li>
                <li>
                  Améliorer le service client et vos besoins de prise en charge.
                </li>
                <li>Vous contacter par e-mail.</li>
                <li>Administrer un concours, une promotion, ou une enquête.</li>
              </ul>
            </p>

            <h2 className="text-xl font-bold mb-2">
              Confidentialité du commerce en ligne
            </h2>
            <p className="text-secondary mb-4">
              Nous sommes les seuls propriétaires des informations recueillies
              sur ce site. Vos informations personnelles ne seront pas vendues,
              échangées, transférées, ou données à une autre société pour
              n'importe quelle raison, sans votre consentement, en dehors de ce
              qui est nécessaire pour répondre à une demande et/ou un service
              spécifique.
            </p>

            <h2 className="text-xl font-bold mb-2">Divulgation à des tiers</h2>
            <p className="text-secondary mb-4">
              Nous ne vendons, n'échangeons et ne transférons pas vos
              informations personnelles identifiables à des tiers. Cela ne
              comprend pas les tierces parties de confiance qui nous aident à
              exploiter notre site Web ou à mener nos affaires, tant que ces
              parties conviennent de garder ces informations confidentielles.
              Nous pensons qu'il est nécessaire de partager des informations
              afin d'enquêter, de prévenir ou de prendre des mesures concernant
              des activités illégales, fraudes présumées, ou violations de nos
              conditions d'utilisation.
            </p>

            <h2 className="text-xl font-bold mb-2">
              Protection des informations
            </h2>
            <p className="text-secondary mb-4">
              Nous mettons en œuvre une variété de mesures de sécurité pour
              préserver la sécurité de vos informations personnelles. Seuls les
              employés qui ont besoin d'effectuer un travail spécifique (par
              exemple, la facturation ou le service à la clientèle) ont accès
              aux informations personnelles identifiables. Les ordinateurs et
              serveurs utilisés pour stocker des informations personnelles
              identifiables sont conservés dans un environnement sécurisé et
              toutes les données sont hébergées sur des serveurs français.
            </p>

            <h2 className="text-xl font-bold mb-2">Cookies</h2>
            <p className="text-secondary mb-4">
              Nos cookies améliorent l'accès à notre site et identifient les
              visiteurs réguliers. En outre, nos cookies améliorent l'expérience
              utilisateur grâce au suivi et au ciblage de ses intérêts.
              Cependant, cette utilisation des cookies n'est en aucune façon
              liée à des informations personnelles identifiables sur notre site.
            </p>

            <h2 className="text-xl font-bold mb-2">Se désabonner</h2>
            <p className="text-secondary mb-4">
              Nous utilisons l'adresse e-mail que vous fournissez pour vous
              envoyer des informations et mises à jour relatives à votre
              commande, des nouvelles de l'entreprise de façon occasionnelle,
              des informations sur des produits liés, etc. Si à n'importe quel
              moment vous souhaitez vous désinscrire et ne plus recevoir
              d'e-mails, des instructions de désabonnement détaillées sont
              incluses en bas de chaque e-mail.
            </p>

            <h2 className="text-xl font-bold mb-2">Consentement</h2>
            <p className="text-secondary mb-4">
              En utilisant notre site, vous consentez à notre politique de
              confidentialité.
            </p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
