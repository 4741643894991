import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { MdSecurity, MdOutlineNotificationsNone } from 'react-icons/md';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiBillLine, RiDeleteBin6Line } from 'react-icons/ri';
import { TiKeyOutline } from 'react-icons/ti';
import { HiOutlineIdentification } from 'react-icons/hi';

const SettingsMenu: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-4 mt-5">
      <button
        onClick={() => navigate('/settings/identity')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <HiOutlineIdentification size={30} />
        </span>
        <span className="text-xl font-bold">Identité</span>
      </button>
      <button
        onClick={() => navigate('/settings/security')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <MdSecurity size={30} />
        </span>
        <span className="text-xl font-bold">Sécurité</span>
      </button>
      {/* <button
        onClick={() => navigate('/settings/privacy')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <AiOutlineEyeInvisible size={30} />
        </span>
        <span className="text-xl font-bold">Confidentialité</span>
      </button> */}
      <button
        onClick={() => navigate('/settings/billing')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <RiBillLine size={30} />
        </span>
        <span className="text-xl font-bold">Facturation</span>
      </button>
      <button
        onClick={() => navigate('/settings/notifications')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <MdOutlineNotificationsNone size={30} />
        </span>
        <span className="text-xl font-bold">Notifications</span>
      </button>
      {/* <button
        onClick={() => navigate('/settings/keyAPI')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <TiKeyOutline size={30} />
        </span>
        <span className="text-xl font-bold">Clés API</span>
      </button> */}
      <button
        onClick={() => navigate('/settings/delete')}
        className="flex items-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[30px] p-2"
      >
        <span className="">
          <RiDeleteBin6Line size={30} />
        </span>
        <span className="text-xl font-bold">Supprimer</span>
      </button>
    </div>
  );
};

export default SettingsMenu;
